module.exports = {
	info       : {
		personal : {
			name     : 'Wayne Cheng',
			email    : 'wayne@che.ng',
			phone    : '424-888-2481',
			location : 'San Diego, CA',
		},
		links    : {
			GitHub    : 'https://github.com/wayncheng/',
			LinkedIn  : 'https://linkedin.com/in/wayncheng',
			// Portfolio : 'https://che.ng/portfolio',
			Portfolio : '/portfolio',
		},
	},
	skills     : [
		'React',
		'Redux',
		'Node.js',
		'Puppeteer',
		'QA Automation',
		'Mocha',
		'Express',
		'Git',
		'JavaScript',
		'jQuery',
		'Sass',
		'CSS',
		'HTML',
		'Handlebars',
		'MySQL',
		'Sequelize',
		'MongoDB',
		'Socket.IO',
		'Firebase',
		'Bootstrap',
		'responsive design',
		'REST APIs',
		'JSON',
		'AJAX',
		'WebVR',		
	],
	experience : [
		{
			headline      : 'Gemological Institute of America (GIA)',
			// subheadline: 'Immersive VR / 3D / 360º real-estate tours in desktop and mobile browsers',
			position      : 'Web Developer',
			contributions : [
				'Facilitated back-end migration of 37 million reports by verifying the data’s integrity using automated testing.',
				'Led a team that built a monitoring dashboard for company executives.',
				'Created an internal app that enabled all departments to run tests in different environments and view results history with zero configuration.',
				'Worked cross-functionally with other departments and built accessible internal tools that automate slow processes. One tool has saved an estimated 300 work hours so far.',
				'Automated QA for report check feature on gia.edu.',
			],
			date          : 'Sep 2018 - Current',
		},
	],
	projects   : {
		vrooms  : {
			headline      : 'VRooms',
			subheadline   : 'Immersive VR / 3D / 360º real-estate tours in desktop and mobile browsers',
			position      : 'A-Frame VR Developer (Full-Stack)',
			contributions : [
				'Adapted A-Frame for use with React.',
				'Created A-Frame environment used in all rooms.',
				'Designed and developed annotation creation system within A-Frame.',
				"In-depth research of A-Frame's core technology and related applications.",
			],
			repo          : 'https://github.com/wayncheng/VRooms',
			demo          : 'https://v-rooms.herokuapp.com/demo',
			demo_text     : 'Tour VRooms',
			date          : '2017',
		},

		mypass  : {
			headline      : 'MyPass',
			subheadline   : 'Biometric user authentication web app using facial and voice recognition APIs',
			position      : 'Full-Stack Developer, Project Manager',
			contributions : [
				'Built prototype application as a proof of concept.',
				'Researched and designed UX/UI for user authentication.',
				'Established the modular codebase structure.',
			],
			repo          : 'https://github.com/wayncheng/mypass',
			demo          : 'https://my-pass.herokuapp.com',
			demo_text     : 'Try MyPass',
			date          : '2017',
			under_maintenance: true,
		},

		jobba   : {
			headline      : 'Jobba',
			subheadline   : 'Job portal powered by 8 APIs to retrieve listings, market data, and helpful career tools',
			position      : 'Front-End Developer',
			contributions : [
				'Responsible for all front-end work and page design.',
				'Planned and structured back-end data flow from APIs to the user’s feed.',
				'Designed a custom filtering/sorting system for results feed.',
			],
			repo          : 'https://github.com/wayncheng/jobba',
			demo          : 'https://wayncheng.github.io/jobba/',
			demo_text     : 'Search Jobba',
			date          : '2017',
			// under_maintenance: true,
		},

		amnesia : {
			headline      : 'Amnesia Game',
			subheadline   : 'Real-time card game for groups to play on their own devices. Built with Socket.IO',
			eyebrow       : 'Real-Time Gaming',
			contributions : [],
			tags          : [ 'Node.js', 'Express', 'Socket.IO', 'WebSockets', 'React' ],
			position      : 'Full-Stack Developer',
			repo          : 'https://github.com/wayncheng/waynomia',
			demo          : 'https://amnesia-game.herokuapp.com',
			demo_text     : 'Play Amnesia',
			date          : '2017',
		},
		hangman : {
			headline      : 'Triple Crown Hangman',
			subheadline   : 'Triple-Crown themed hangman game as difficult as its namesake.',
			position      : 'Front-End Developer',
			contributions : [ 'Wrote front-end logic with jQuery and created all graphics using Adobe Illustrator.' ],
			repo          : 'https://github.com/wayncheng/hangman-game',
			demo          : 'https://wayncheng.github.io/hangman-game',
			demo_text     : 'Play Hangman',
			date          : '2017',
		},
		hotdog  : {
			headline      : 'Hotdog or Not',
			subheadline   : 'Inspired by HBO’s Silicon Valley, this app identifies if a photo contains a hotdog',
			position      : 'Full-Stack Developer',
			contributions : [
				'Originally built with jQuery, but eventually converted to full-stack application with Node.js to handle API calls more securely.',
			],
			repo          : 'https://github.com/wayncheng/hotdog-or-not',
			demo          : 'https://hotdog-or-not.herokuapp.com',
			demo_text     : 'Examine Hotdogs',
			date          : '2017',
		},
	},
};
