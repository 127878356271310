export const SET_METADATA = 'app/SET_METADATA';
export const SET_THEME = 'app/SET_THEME';

const initialState = {
	metadata : {},
	theme: 'dark',
};

//==================================================
export default (state = initialState, action) => {
	switch (action.type) {
		case SET_METADATA:
			return {
				...state,
				metadata : action.metadata,
			};
		case SET_THEME:
			return {
				...state,
				theme : action.theme,
			};

		default:
			return state;
	}
};

//==================================================
export const setMetadata = metadata => dispatch =>
	dispatch({
		type     : SET_METADATA,
		metadata,
	});

// Dark / Light Mode ====================================================================
// - Dark mode is the default mode and will be used more often.
// - If client OS preference is dark, it will always be dark mode, no matter the time.
// - If client OS preference is light or N/A, dark/light mode depends on time of day.
// - Nightime is from 6PM to 7AM
//---------------------------------------------------------------------------------------
export const setTheme = (theme = 'dark') => dispatch => {

	// OS Preferences
	const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
	const prefersLightMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches;

	// const clientDate = new Date();
	// const dayStart = 0;
	// const dayEnd = 1;
	const dayStart = 7;
	const dayEnd = 18;
	const clientHour = new Date().getHours();
	const isDay = clientHour >= dayStart && clientHour < dayEnd;
	console.log('is daytime:', isDay);

	if (prefersDarkMode || !isDay) {
		console.log('theme: dark');
		document.querySelector('html').classList.add('theme-dark');
		document.querySelector('html').classList.remove('theme-light');
	} else if (isDay) {
		console.log('theme: light');
		theme = 'light';
		document.querySelector('html').classList.add('theme-light');
		document.querySelector('html').classList.remove('theme-dark');
	}

	dispatch({
		type     : SET_THEME,
		theme,
	});
};

///////////////////////////////////////////////////////////////////////
