import React, {Component} from 'react';
// import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Badge.scss';

class Badge extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<span className={classNames('badge', this.props.className)}>
				{this.props.text}
				{this.props.children}
			</span>
		);
	}
}

export default Badge;

Badge.defaultProps = {};
Badge.propTypes = {
	text : PropTypes.string,
};
