import React, { Component } from 'react';
import './ProgressivePhoto.scss';

class ProgressivePhoto extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fullSrc: null,
		};
	}

	componentDidMount = () => {
	}
	
	handleClick = event => {
		this.setState({
			fullSrc: this.props.full,
		})
		
	}

	render() {
		return (
			<div className="prog-photo-container" style={this.props.container_style} onClick={this.handleClick}>
				<img src={this.props.blur} alt="" className="blurholder" style={{}} />
				{/* <img src={this.props.full} alt="" className="full-res" style={{}} /> */}
				<img src={this.state.fullSrc} alt="" className="full-res" style={{}} />

				<div className="skirt" />
			</div>
		);
	}
}
export default ProgressivePhoto;

ProgressivePhoto.defaultProps = {
	blur: '../assets/hood-blur.jpg',
	container_style: {},
};
