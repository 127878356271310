import React, { Component } from 'react';
import {
	PageRoot,
	Nav,
	PhotoGrid,
	Footer,
	Eyebrow,
	Headline,
	// Subheadline
} from '../../components';
// import MeterDial from './MeterDial';
import './PhotographyPage.scss';

class PhotoFluidPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// blur: 24
		};
	}


	componentDidMount = () => {
		// this.openFromUrl();

		const deviceWidth = window.screen.width;
		const deviceHeight = window.screen.height;
		// console.log('deviceHeight:',deviceHeight)
		// console.log('deviceWidth:',deviceWidth)
		console.log(`device dimensions: ${deviceHeight} x ${deviceWidth}`);

		this.setState({
			deviceHeight,
			deviceWidth,
			// width,
			// height,
			// orientation,
		});

		this.tailor();
	};

	tailor = () => {
		const width = window.innerWidth;
		const height = window.innerHeight;

		let orientation = 'landscape';
		if (height > width) {
			orientation = 'portrait';
		}
		const updated = {
			width,
			height,
			orientation,
		};

		console.log('tailored:', updated);

		this.setState(updated);

		return {
			width,
			height,
			orientation,
		};
	};

	// render //////////////////////////////////////////////////
	render() {
		return (
			<PageRoot id='photo-fluid-root' page_title='photography'>
				<Nav back='transparent' front='white' />
				<main>
					<header id='photo-header' className='ws-header'>
						<div className='photo-bg-wrap'>
							<div className='photo-bg blurholder' />
							<div className='photo-bg full-photo-bg' />
						</div>
						<div className='above-fold'>
							<div className='liner'>
								<div className='header-content'>
									<Eyebrow text='Fine-Art / Landscape' />
									<Headline text='Photography' />
									{/* <Subheadline text="by Wayne Cheng" /> */}
								</div>
							</div>
						</div>
					</header>
					<article className='photo-grid-wrap'>
						<PhotoGrid
							location={this.props.history.location}
							gallery_id={'portfolio'}
							photos={[
								// tier 1
								'7209',
								'0755',
								'6993',
								// tier 2
								'0549',
								'7383',
								'7680',
								// tier 3+
								'0037',
								'0828',
								'2277',
								'2396',
								'2725',
								'3176',
								'6274',
								'6888',
								'6924',
								'7846',
								'D0017',
								'D0271',
							]}
						/>
					</article>
				</main>
				<Footer
					style={{
						backgroundColor : 'transparent',
					}}
				/>
			</PageRoot>
		);
	}
}
export default PhotoFluidPage;
