import { 
	SEARCH_REQUESTED, 
	SEARCH_FINISHED, 
	UPDATE_QUERY,
	UPDATE_RESULTS,
	UPDATE_SUGGESTION,
} from './actionTypes';

import search_index from '../components/Search/data/search_index';

const initialState = {
	query: '',
	suggestion: {
		tag: '',
		count: 0,
	},
	isSearching: false,
	searchDone: false,
	searchStatus: 'ok',  // ok, error
	matchType: 'none',   // exact, partial, none
	results: {
		data: []
	},
	resultCache: {}
}

// TODO: find permanent solution to access own state for the search module (state.search)
let search_cache = {}

export default (state = initialState, action) => {
	switch (action.type) {

		case SEARCH_REQUESTED:
			return {
				...state,
				isSearching: true,
				searchDone: false,
			}

		case SEARCH_FINISHED:
			return {
				...state,
				isSearching: false,
				searchDone: true,
			}

		case UPDATE_RESULTS:
			// * Cache the Search
			search_cache = {
				...state.resultCache,
				[action.results.query]: action.results
			}
			
			return {
				...state,
				results: action.results,
				resultCache: search_cache,
			}

		case UPDATE_QUERY:
			return {
				...state,
				query: action.query
			}

		case UPDATE_SUGGESTION:
			return {
				...state,
				suggestion: action.suggestion
			}

		default:
			return state
	}
}

// Form Updates ------------------------------------
export const updateQuery = query => dispatch => {
	dispatch({
		type: UPDATE_QUERY,
		query,
	})
}
export const updateSuggestion = suggestion => dispatch => {
	dispatch({
		type: UPDATE_SUGGESTION,
		suggestion,
	})
}

// Search Handling -------------------------------------------
export const requestSearch = () => dispatch => {
	dispatch({ type: SEARCH_REQUESTED })
}
export const finishSearch = () => dispatch => {
	dispatch({ type: SEARCH_FINISHED })
}
export const updateResults = results => dispatch => {
	dispatch({
		type: UPDATE_RESULTS,
		results,
	})
}

// * SEARCH -------------------------------------------

export const search = (query) => dispatch => {
	console.log('\n-----\n')
	console.log('search_index.length:',search_index.length)

	// # Set flag to indicate request is in progress (isSearching)
	dispatch(requestSearch())
	let q = query.trim();

	// # PERFORM SEARCH * * * * * * * * * * * * * * * * * * *
	let fullResults = [];
	let finalRes = {};
	let matchCount = {};  // used later on to track result popularity
	let mostPopular = '';
	let highestCount = 0;
	let suggestion = {};
	let matches = {
		// "github": [],
		// "portfolio": [],
	}
	let matchesArr = [];



	// # 1. Check search cache first
	let cachedResponse = search_cache[q];
	if (cachedResponse) {
		console.log(">>> CACHED RESPONSE FOUND <<<")
		// dispatch(updateSuggestion(cachedResponse.suggestion))
		suggestion = cachedResponse.suggestion;
		finalRes = cachedResponse
	}

	// 2. If query is not found in the cache, perform the search
	else {

		// Only perform search if there is an actual query.
		// If not, returned results should be empty
		if ( q.length > 0) {
			matchCount = {};
			matches = {};

			// + Filter entire search index down to results that contain tags, which match the query
			// - For each item in the index .........
			fullResults = search_index.filter((item) => {
				let tags = item.tags;

				// let tagMatch = tags.some(tag => {
				let tagMatch = tags.filter(tag => {
					let startsWithQuery = tag.startsWith(q);
					
					// + Track with Hash Table. Either increase count or initiate new
					if (startsWithQuery) {
						if (matchCount[tag]){
							matchCount[tag]++
						}
						else {
							matchCount[tag] = 1;
						}

						if (matches[tag]){
							matches[tag].push(item)
						}
						else {
							matches[tag] = [item]
						}
					}
					
					return startsWithQuery === true;
				})

				// + Also check if the name is a match
				let itemNameMatch = item.name.startsWith(q);
				
				// + If item tag or item name match, don't filter out
				return tagMatch || itemNameMatch;
				
			})
			
			// + Determine most popular result
			// + Convert matches object into array
			for (let key in matches) {
				let items = matches[key];
				let count = items.length;

				matchesArr.push({
					name: key,
					items,
				})

				if (count > highestCount){
					mostPopular = key;
					highestCount = count;
				}
			}
			
			// TODO: Weighted results so the terms aren't sorted by quantity of results
			// + Sort matches array (descending order by count)
			matchesArr.sort( (a,b) => {
				let aCount = a.items.length;
				let bCount = b.items.length;

				return bCount - aCount;
			})

			// for (let key in matchCount) {
			// 	let count = matchCount[key];
				
			// 	if (count > highestCount){
			// 		mostPopular = key;
			// 		highestCount = count;
			// 	}
			// }
			
			suggestion = {
				tag: mostPopular,
				count: highestCount,
			}
		}
		
		// Prepare Final Response
		finalRes = {
			query,
			data: fullResults,
			suggestion,
			match_counts: matchCount,
			matches: matchesArr,
		}
		
	}
	console.log('finalRes ===>',finalRes)
	
	// # Update Query Suggestion
	dispatch(updateSuggestion(suggestion))
	
	// # Update Results
	dispatch(updateResults(finalRes))

	// # Remove flag
	dispatch(finishSearch())
	
	// TODO: Weighted search results
	// TODO: Analytics to see what people search?
	// TODO: Error handling
}




///////////////////////////////////////////////////////////////////////
