import React from 'react';
import './Landing.scss';
import { 
	PageRoot, 
	Footer, 
	Nav,
} from '../../components';

const Landing = props => {
	return (
		<PageRoot id="landing-root" className="landing-minimal">
			{/* <Nav front="white" back="transparent"/> */}
			<Nav/>
			<main className="">
				<article className="full-screen-wrap bladerunner">
					<div className="content">
						<h1 className="headline">Wayne Cheng's Website</h1>

						<ul className="">
							<li>
								<a className="link-text" href="/portfolio">
									Portfolio
								</a>
							</li>
							<li>
								<a className="link-text" href="/photo">
									Photography
								</a>
							</li>
							<li>
								<a className="link-text" href="/contact">
									Contact
								</a>
							</li>
						</ul>
					</div>
				</article>
			</main>
			<Footer />
		</PageRoot>
	);
};
export default Landing;
