import React from 'react';
import ResListBullets from './ResListBullets';

const ResExperience = props => {
	let { data, dataKey } = props;
	return (
		<section className="resume-section" key={`project-section-${dataKey}`} _qa="res-project">
			{/* Headline */}
			<h4 className="section-title">{data.headline}</h4>

			{/* Position / Job Title */}
			<p className="job-title">{data.position}</p>

			{/* Date */}
			<span className="resume-date">{data.date}</span>

			{/* Subheadline (optional) */}
			{data.subheadline && <p className="section-subhead">{data.subheadline}</p>}

			{/* Contributions */}
			<ResListBullets data={data.contributions} />

			{/* Project Links */}
			<div className="project-link-group" _qa="link-group">
				{data.repo && (
					<a href={data.repo} className="ws-btn project-link project-link-code" _qa="link-code" target="_blank" rel="noopener noreferrer">
						GitHub
					</a>
				)}
				{(data.demo && !data.under_maintenance) && (
					<a href={data.demo} className="ws-btn project-link project-link-demo" _qa="link-demo" target="_blank" rel="noopener noreferrer">
						Demo
					</a>
				)}
			</div>
		</section>
	);
};
export default ResExperience;
