import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { search,updateQuery } from '../../../modules/search';
import QuerySuggestion from './QuerySuggestion';

class SearchInput extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	handleQueryChange = event => {
		event.preventDefault();
		const { value } = event.target;
		let query = value.toLowerCase();

		this.props.updateQuery(query);

		this.props.search(query);
	}

	render() {
		return (
			<div className="input-group">
				<label htmlFor="search">Search</label>
				<input
					type="text"
					id="search"
					name="search"
					className="search-input ws-input"
					placeholder="Search"
					onChange={this.handleQueryChange}
					value={this.props.query}
					autoFocus
				/>
				<QuerySuggestion />
			</div>
		)
	}
}
// export default SearchInput;

const mapStateToProps = state => ({
	isSearching: state.search.isSearching,
	query: state.search.query,
	suggestion: state.search.suggestion,
})

const mapDispatchToProps = dispatch => bindActionCreators({
	updateQuery,
	search,
}, dispatch)

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(SearchInput)