import React, {Component} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import SearchForm from './Form/SearchForm';
import SearchResultList from './Results/SearchResultList';

class SearchContainer extends Component {
	constructor(props){
		super(props)
		this.state = {
			results: []
		}
	}
	
	componentDidMount() {
		this.setState({
			results: this.props.results
		})
	}
	

	render(){
		return(
			<div className="gs-container container">
				<SearchForm />
				<SearchResultList results={this.props.results.data} matches={this.props.results.matches} />
			</div>
		)
	}
}
// export default SearchContainer;

const mapStateToProps = state => ({
	isSearching: state.search.isSearching,
	results: state.search.results,
})

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch)

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(SearchContainer)

SearchContainer.defaultProps = {
	// results: search_index.portfolio.results,
}