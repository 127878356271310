// import ReactDOM from 'react-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { 
	faGoogle, 
	faTwitter, 
	faGithub, 
	faApple, 
} from '@fortawesome/free-brands-svg-icons'
import { 
	faBars,
	faPlus,
	faEnvelope,
	faLock,
	faUser,
	faCog,
	faTimes,
	faTrashAlt,
	faSave,
	faMapMarkerAlt,
	faCalendarAlt,
	faCalendar,
} from '@fortawesome/free-solid-svg-icons'


const buildIconLibrary = () => {
	library.add(
		faGoogle, 
		faApple, 
		faTwitter,
		faGithub,
		faBars,
		faPlus,
		faEnvelope,
		faLock,
		faUser,
		faCog,
		faTimes,
		faTrashAlt,
		faSave,
		faMapMarkerAlt,
		faCalendarAlt,
		faCalendar,
	)
}
export default buildIconLibrary;