import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PhotoSwipe } from 'react-photoswipe';
import '../../style/libs/react-photoswipe/photoswipe.css';
// import './PhotoSwipeGallery.scss';

import photoInfo from '../../data/photo_data';

class PhotoSwipeGallery extends Component {
	constructor(props) {
		super(props);
		this.state = {
			gid    : '',
			pid    : '',
			isOpen : false,
		};
	}
	////////////////////////////////////////////////////
	// componentDidMount ===============================
	// componentDidMount = () => {
	// 	this.openFromUrl();
	// };

	// openFromUrl = () => {
	// 	// che.ng/photo#&gid=0&pid=10
	// 	const { location } = this.props;
	// 	const { hash } = location;
	// 	if (hash.length > 0) {
	// 		const queries = hash.split('&');
	// 		let gid, pid;

	// 		for (let i = 0; i < queries.length; i++) {
	// 			const query = queries[i];
	// 			if (query.indexOf('gid=') === 0) gid = query.substr(4);
	// 			if (query.indexOf('pid=') === 0) pid = query.substr(4);
	// 		}
	// 		console.log(`gallery: ${gid} / photo: ${pid}`);
	// 	}
	// };

	getThumbnailContent = (item) => {
		return (
			<img
				src={item.thumbnail}
				alt={item.caption}
				id={`img-${item.gid}-${item.pid}`}
				data-gid={item.gid}
				data-pid={item.pid}
			/>
		);
	};

	handleClose = (event) => {
		event.preventDefault();
		this.setState({ isOpen : false, });
	};

	handleOpen = (event) => {
		event.preventDefault();
		this.setState({ isOpen: true });
	};

	//////////////////////////////////////////////////////////////////////////////////
	render() {
		const items = this.props.photos.map((file, i) => {
			let { w, h, title} = photoInfo[file];
			// let thumbLocation = 'https://s3-us-west-2.amazonaws.com/assets.che.ng/img/thumb/';
			// if (i < 6) thumbLocation = `preloaded/`;
			return {
				src       : `https://s3-us-west-2.amazonaws.com/assets.che.ng/img/2k/${file}.jpg`,
				w,
				h,
				title,
				pid       : file,
				// caption,
				// index     : i,
				gid       : this.props.gallery_id,
			};
		});


		return (
			<div id='photoswipe-root'>
				<PhotoSwipe
					items={items}
					options={{
						preload    : [ 1, 3 ], // lazy load 1 photo before and 3 photos after current
						galleryUID : this.props.gallery_id,
					}}
					thumbnailContent={this.getThumbnailContent}
					// isOpen={this.state.isOpen}
					// onClose={this.handleClose}
				/>
				{/* <button onClick={this.handleOpen}>click</button> */}
			</div>
		);
	}
	//////////////////////////////////////////////////////////////////////////////////
}

export default PhotoSwipeGallery;

PhotoSwipeGallery.defaultProps = {
	// photos: ["7209", "0037"],
	photos     : [],
	gallery_id : 0,
};
PhotoSwipeGallery.propTypes = {
	photos : PropTypes.arrayOf(PropTypes.string),
};
