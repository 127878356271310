export default {
	"0037": {
		 width: 1920,
		 height: 975,
		 aspect_ratio: 1.969230769,
		 title: "2020",
		 caption: "Glasses"
	},
	"0549": {
		 width: 1920,
		 height: 1235,
		 aspect_ratio: 1.55465587,
		 title: "",
		 caption: "Beijing Terminal"
	},
	"0755": {
		 width: 1920,
		 height: 918,
		 aspect_ratio: 2.091503268,
		 title: "",
		 caption: "Bay Bridge Lights"
	},
	"0828": {
		 width: 1920,
		 height: 1280,
		 aspect_ratio: 1.5,
		 title: "",
		 caption: "Great Wall of China"
	},
	"1474": {
		 width: 1920,
		 height: 1051,
		 aspect_ratio: 1.826831589,
		 title: "",
		 caption: "iPhone & Macbook Pro"
	},
	"1531": {
		 width: 1920,
		 height: 973,
		 aspect_ratio: 1.97327852,
		 title: "",
		 caption: "Couple on the Rocks"
	},
	"1575": {
		 width: 1920,
		 height: 1158,
		 aspect_ratio: 1.658031088,
		 title: "",
		 caption: "Long Exposure from Del Mar Dog Beach"
	},
	"1705": {
		 width: 1920,
		 height: 1238,
		 aspect_ratio: 1.55088853,
		 title: "",
		 caption: "La Jolla Shores Sunset"
	},
	"1745": {
		 width: 1280,
		 height: 1920,
		 aspect_ratio: 0.666666667,
		 title: "",
		 caption: "La Jolla Shores Sunset"
	},
	"1784": {
		 width: 1920,
		 height: 1280,
		 aspect_ratio: 1.5,
		 title: "",
		 caption: "La Jolla Shores Sunset"
	},
	"1859": {
		 width: 1920,
		 height: 1129,
		 aspect_ratio: 1.700620018,
		 title: "",
		 caption: "Fishing Off a Cliff at Sunset"
	},
	"2277": {
		 width: 1920,
		 height: 1371,
		 aspect_ratio: 1.400437637,
		 title: "",
		 caption: "Friendly Sheep in New Zealand"
	},
	"2396": {
		 width: 1920,
		 height: 1189,
		 aspect_ratio: 1.614802355,
		 title: "",
		 caption: "Car Hood Series Light Trails with Accidental Dew on Lens"
	},
	"2422": {
		 width: 1280,
		 height: 1920,
		 aspect_ratio: 0.666666667,
		 title: "",
		 caption: "San Diego Skyline from Coronado Island (Daytime)"
	},
	"2424": {
		 width: 1920,
		 height: 1280,
		 aspect_ratio: 1.5,
		 title: "",
		 caption: "San Diego Skyline from Coronado Island (Daytime)"
	},
	"2607": {
		 width: 1920,
		 height: 1189,
		 aspect_ratio: 1.614802355,
		 title: "",
		 caption: "Car Hood Series U-turn Long(ish) Exposure"
	},
	"2725": {
		 width: 1920,
		 height: 978,
		 aspect_ratio: 1.963190184,
		 title: "",
		 caption: "Coronado Bridge"
	},
	"3140": {
		 width: 1920,
		 height: 1189,
		 aspect_ratio: 1.614802355,
		 title: "",
		 caption: "Car Hood Series Warp Speed Through Downtown Del Mar"
	},
	"3176": {
		 width: 1920,
		 height: 1257,
		 aspect_ratio: 1.527446301,
		 title: "",
		 caption: "Plane Makes Final Approach to SAN Airport Just Over Downtown SD and the Interstate 5"
	},
	"6044": {
		 width: 3043,
		 height: 1080,
		 aspect_ratio: 2.817592593,
		 title: "",
		 caption: "San Diego Skyline from Coronado Island (Night Time)"
	},
	"6274": {
		 width: 1920,
		 height: 1280,
		 aspect_ratio: 1.5,
		 title: "",
		 caption: "Golden Gate Bridge"
	},
	"6491": {
		 width: 1920,
		 height: 1040,
		 aspect_ratio: 1.846153846,
		 title: "",
		 caption: "Fighter Jet at Miramar Air Show"
	},
	"6888": {
		 width: 1920,
		 height: 1280,
		 aspect_ratio: 1.5,
		 title: "",
		 caption: "Antelope Canyon Curves"
	},
	"6924": {
		 width: 1920,
		 height: 1279,
		 aspect_ratio: 1.501172791,
		 title: "451",
		 caption: "Antelope Canyon Flame"
	},
	"6993": {
		 width: 1920,
		 height: 1280,
		 aspect_ratio: 1.5,
		 title: "143",
		 caption: "Antelope Canyon Heart"
	},
	"7209": {
		 width: 1920,
		 height: 1128,
		 aspect_ratio: 1.70212766,
		 title: "45",
		 caption: "Horseshoe Bend Sunset",
		 location: 'Page, Arizona',
		 date: '1-1-2015'
	},
	"7355": {
		 width: 1920,
		 height: 1280,
		 aspect_ratio: 1.5,
		 title: "",
		 caption: "Michael Overlooking the Horseshoe Bend"
	},
	"7383": {
		 width: 1920,
		 height: 1273,
		 aspect_ratio: 1.508248233,
		 title: "",
		 caption: "Mount Soledad Simplified",
		 location: 'La Jolla, California',
		 date: '6-6-2017'
	},
	"7598": {
		 width: 1920,
		 height: 1309,
		 aspect_ratio: 1.466768526,
		 title: "",
		 caption: "Michael Photographing from Inside the Matrix"
	},
	"7674": {
		 width: 1920,
		 height: 1280,
		 aspect_ratio: 1.5,
		 title: "",
		 caption: "Coral Pink Sand Dunes Partial Silhouette"
	},
	"7680": {
		 width: 1920,
		 height: 1280,
		 aspect_ratio: 1.5,
		 title: "",
		 caption: "Coral Pink Sand Dunes Nearly Pristine"
	},
	"7780": {
		 width: 1280,
		 height: 1920,
		 aspect_ratio: 0.666666667,
		 title: "",
		 caption: "Coral Pink Sand Dune Horizon Silhouette"
	},
	"7781": {
		 width: 1280,
		 height: 1920,
		 aspect_ratio: 0.666666667,
		 title: "",
		 caption: "Coral Pink Sand Dune Horizon Silhouette"
	},
	"7841": {
		 width: 1920,
		 height: 778,
		 aspect_ratio: 2.467866324,
		 title: "",
		 caption: "Milky Way"
	},
	"7846": {
		 width: 1920,
		 height: 1280,
		 aspect_ratio: 1.5,
		 title: "",
		 caption: "Belly of the Cabrillo Bridge"
	},
	"7875": {
		 width: 1920,
		 height: 976,
		 aspect_ratio: 1.967213115,
		 title: "",
		 caption: "Milky Way"
	},
	"8497": {
		 width: 1920,
		 height: 1280,
		 aspect_ratio: 1.5,
		 title: "",
		 caption: "USS Midway on July 4th"
	},
	"8807": {
		 width: 1920,
		 height: 1268,
		 aspect_ratio: 1.514195584,
		 title: "",
		 caption: "Badwater Basin Sunset"
	},
	"8893": {
		 width: 1280,
		 height: 1920,
		 aspect_ratio: 0.666666667,
		 title: "",
		 caption: "Faint Milky Way (Taken in Death Valley)"
	},
	"9157": {
		 width: 1280,
		 height: 1920,
		 aspect_ratio: 0.666666667,
		 title: "",
		 caption: "Dried up Bed Near a Death Valley Dune"
	},
	"9165": {
		 width: 1920,
		 height: 1280,
		 aspect_ratio: 1.5,
		 title: "",
		 caption: "Lone Man Taking a Selfie From the Top of a Death Valley Dune"
	},
	"D0017": {
		 width: 1920,
		 height: 1341,
		 aspect_ratio: 1.431767338,
		 title: "",
		 caption: "Bird's-Eye View of the Amtrak Coasting by the Del Mar Fairgrounds  (Taken with Drone)"
	},
	"D0145": {
		 width: 1920,
		 height: 1376,
		 aspect_ratio: 1.395348837,
		 title: "",
		 caption: "Bird's-Eye View of the Amtrak Coasting by the Del Mar Fairgrounds  (Taken with Drone)"
	},
	"D0271": {
		 width: 1920,
		 height: 1439,
		 aspect_ratio: 1.334259903,
		 title: "",
		 caption: "Bird's-Eye View of the Traffic on Coronado Bridge (Taken with Drone)"
	},
	"T0547": {
		 width: 1920,
		 height: 1920,
		 aspect_ratio: 1,
		 title: "Flower Dome Series",
		 caption: "Pink and White Flower from the Flower Dome of Gardens by the Bay in Singapore"
	},
	"T0549": {
		 width: 1920,
		 height: 1920,
		 aspect_ratio: 1,
		 title: "Flower Dome Series",
		 caption: "Yellow and Orange Flower from the Flower Dome of Gardens by the Bay in Singapore"
	},
	"T0560": {
		 width: 1920,
		 height: 1920,
		 aspect_ratio: 1,
		 title: "Flower Dome Series",
		 caption: "White Flower from the Flower Dome of Gardens by the Bay in Singapore"
	},
	"T0563": {
		 width: 1920,
		 height: 1343,
		 aspect_ratio: 1.429635145,
		 title: "Arc Series",
		 caption: "Cable Car on the Way to Tian Tan Big Buddha in Hong Kong"
	},
	"T0619": {
		 width: 1920,
		 height: 1920,
		 aspect_ratio: 1,
		 title: "Arc Series",
		 caption: "Detailed floor of Senado Square in Macau"
	},
	"T0628": {
		 width: 1920,
		 height: 1424,
		 aspect_ratio: 1.348314607,
		 title: "Arc Series",
		 caption: "Curved glass wall in the Flower Dome of Gardens by the Bay in Singapore"
	},
	"T2012": {
		 width: 1920,
		 height: 1920,
		 aspect_ratio: 1,
		 title: "Instax Series",
		 caption: "Instax Mini of the blue wall at the Unique Space in Los Angeles"
	},
	"T2013": {
		 width: 1920,
		 height: 1920,
		 aspect_ratio: 1,
		 title: "Instax Series",
		 caption: "Instax Mini of white cross beams at the Unique Space in Los Angeles"
	},
	"T2014": {
		 width: 1920,
		 height: 1920,
		 aspect_ratio: 1,
		 title: "Instax Series",
		 caption: "Instax Mini of the leaf wall at the Unique Space in Los Angeles"
	},
	"T2074": {
		 width: 1920,
		 height: 918,
		 aspect_ratio: 2.091503268,
		 title: "Abridged Series",
		 caption: "Bay Bridge Lights"
	},
	"T2134": {
		 width: 1920,
		 height: 1280,
		 aspect_ratio: 1.5,
		 title: "Abridged Series",
		 caption: "Belly of the Cabrillo Bridge"
	},
	"T2140": {
		 width: 1920,
		 height: 978,
		 aspect_ratio: 1.963190184,
		 title: "Abridged Series",
		 caption: "Coronado Bridge at Sunrise"
	},
	"T2229": {
		 width: 1920,
		 height: 1213,
		 aspect_ratio: 1.582852432,
		 title: "Third Wheel Series",
		 caption: "Irene & Kevin during their engagement photo shoot"
	},
	"T2230": {
		 width: 1920,
		 height: 1351,
		 aspect_ratio: 1.421169504,
		 title: "Third Wheel Series",
		 caption: "Irene & Kevin hiking through New Zealand"
	},
	"T2234": {
		 width: 1920,
		 height: 1249,
		 aspect_ratio: 1.537229784,
		 title: "Third Wheel Series",
		 caption: "Irene & Kevin just before Kevin proposed"
	},
	"T3087": {
		 width: 1920,
		 height: 1189,
		 aspect_ratio: 1.614802355,
		 title: "Drive By Series",
		 caption: "Hood of the 5 series at night with dew built up on the lens"
	},
	"T3088": {
		 width: 1920,
		 height: 1189,
		 aspect_ratio: 1.614802355,
		 title: "Drive By Series",
		 caption: "Hood of the 5 series at sunrise while I U-turn"
	},
	"T3089": {
		 width: 1920,
		 height: 1189,
		 aspect_ratio: 1.614802355,
		 title: "Drive By Series",
		 caption: "Hood of the 5 series zooming (at the speed limit of course) through Del Mar at night."
	},
	"T3656": {
		 width: 1920,
		 height: 1439,
		 aspect_ratio: 1.334259903,
		 title: "Transport Series",
		 caption: "Straight down view of the traffic on Coronado bridge"
	},
	"T3657": {
		 width: 1920,
		 height: 1376,
		 aspect_ratio: 1.395348837,
		 title: "Transport Series",
		 caption: "Birds-Eye View of the Amtrak Coasting by the Del Mar Fairgrounds  (Taken with Drone)"
	},
	"T3658": {
		 width: 1920,
		 height: 1341,
		 aspect_ratio: 1.431767338,
		 title: "Transport Series",
		 caption: "Birds-Eye View of the Amtrak Coasting by the Del Mar Fairgrounds  (Taken with Drone)"
	}
};