import React, {Component} from 'react';
// import links from '../../data/links.js';
const links = require('../../data/links.js');
const iconDir = 'icons/'


class LinkCluster extends Component {
	constructor(props){
		super(props)
		this.state = {}
	}

	render(){
		return(
			
		
		// <div className="link-cluster icon-links">
		<ul className="link-cluster icon-links">
			<li>
				<a href={links.github}>
					<img src={iconDir+"github.svg"} alt="Wayne's Github" />
				</a>
			</li>
			<li>
				<a href={links.linkedin}>
					<img src={iconDir+"linkedin.svg"} alt="Wayne's LinkedIn" />
				</a>
			</li>
			<li>
				<a href={links.instagram}>
					<img src={iconDir+"instagram.svg"} alt="Wayne's Instagram" />
				</a>
			</li>
			<li>
				<a href={links.twitter}>
					<img src={iconDir+"twitter.svg"} alt="Wayne's Twitter" />
				</a>
			</li>
			<li>
				<a href={links['500px']}>
					<img src={iconDir+"500px.svg"} alt="Wayne's 500px" />
				</a>
			</li>
		</ul>
	// </div>
		)
	}
}
export default LinkCluster