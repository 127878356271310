

export const SEARCH_REQUESTED  = 'search/SEARCH_REQUESTED'
export const SEARCH_FINISHED   = 'search/SEARCH_FINISHED'
export const UPDATE_QUERY      = 'search/UPDATE_QUERY'
export const UPDATE_SUGGESTION = 'search/UPDATE_SUGGESTION'
export const UPDATE_RESULTS    = 'search/UPDATE_RESULTS'


export const TOGGLE_MODAL = 'modal/TOGGLE_MODAL'
export const OPEN_MODAL = 'modal/OPEN_MODAL'
export const CLOSE_MODAL = 'modal/CLOSE_MODAL'