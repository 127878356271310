import React, { Component } from 'react';
import {
	PageRoot,
	Nav,
	PhotoGrid,
	Footer,
	Eyebrow,
	Headline,
	Subheadline
} from '../../components';
// import MeterDial from './MeterDial';

class Photography extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// blur: 24
		};
	}

	// componentDidMount = () => {
	// 	// http://localhost:3000/photo#&gid=0&pid=10
	// 	console.log('this.props.match:',this.props.match);
	// 	console.log('this.props.history:',this.props.history);
	// 	console.log('this.props.location:',this.props.location);
	// }
	// handleFocus = event => {
	// 	document.querySelector('.autofocus-target').classList.add('focusing');
	// 	document.querySelector('.linear-focus-meter').classList.add('focusing');
	// };

	// render //////////////////////////////////////////////////
	render() {
		return (
			<PageRoot id="photography-root" page_title="photography">
				<Nav back="transparent" front="white" />
				<main>
					<header id="photography-header" className="ws-header" >
						<div className="photo-background blurholder">
							{/* <img src="#" alt=""/> */}
						</div>
						<div className="header-content">
							<Eyebrow text="Fine-Art / Landscape" />
							<Headline text="Photography" />
							<Subheadline text="by Wayne Cheng" />
						</div>
					</header>
					<article className="photo-grid-wrap">
						<PhotoGrid
							photos={[
							// tier 1
								'7209',
								'0755',
								'6993',
							// tier 2
								'0549',
								'7383',
								'7680',
							// tier 3+
								'0037',
								'0828',
								'2277',
								'2396',
								'2725',
								'3176',
								'6274',
								'6888',
								'6924',
								'7846',
								'D0017',
								'D0271'
							]}
						/>
					</article>
				</main>
				<Footer />
			</PageRoot>
		);
	}
}
export default Photography;
