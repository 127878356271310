import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import {Navbar,NavItem} from 'react-materialize';
import {slide as Menu} from 'react-burger-menu';
import NavLinks from './NavLinks';
import './Nav.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class Nav extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	showSettings(event) {
		event.preventDefault();
	}

	render() {
		const {front, back} = this.props;
		// let bg = '';
		// let fg = '';
		// let burgerColor = '#000000';
		// if (front) fg = `fg-${front}`;
		// if (front && (front === 'white' || front === 'light')) burgerColor = '#ffffff';
		// if (back) bg = `bg-${back}`;
		// let burgerSource = `icons/burger-${burgerColor}-a-round.svg`;

		// const burgerColor = front === 'white' || front === 'light' ? '#ffffff' : '#000000';
		const bg = back ? `bg-${back}` : '';
		const fg = front ? `fg-${front}` : '';

		return (
			<nav className={classNames('globalnav', fg, bg)}>
				<div className='wrap gn-wrap'>
					<Link to='/' className={classNames('title gn-title', fg)}>
						Wayne
					</Link>
					<NavLinks id='default-nav' className={classNames('gn-list', fg)} fg={fg} />
					<div className={'gn-bm-wrap'}>
						<Menu
							className={'gn-menu'}
							// customBurgerIcon={ <img src={burgerSource} alt="Main Menu" /> }
							customBurgerIcon={<FontAwesomeIcon icon='bars' className={classNames('burger-icon')} />}
							customCrossIcon={<FontAwesomeIcon icon='times' className={classNames('burger-close-icon')} />}
							// customBurgerIcon={<FontAwesomeIcon icon='bars' className={classNames('burger-icon', fg)} />}
							// customCrossIcon={<FontAwesomeIcon icon='times' className={classNames('burger-close-icon', fg)} />}
							crossButtonClassName="burger-close-btn"
							right
							width={'66%'}
						>
							<NavLinks
								id='burger-nav'
								className='burger-nav-list gn-list'
								// className={classNames('burger-nav-list gn-list', fg)}
								// fg={fg}
								include='home'
							/>
						</Menu>
					</div>
				</div>
			</nav>
		);
	}
}
export default Nav;

Nav.propTypes = {
	back  : PropTypes.string,
	front : PropTypes.string,
};

Nav.defaultProps = {
	// front : 'black',
	// back  : 'transparent',
};

// <Navbar className='globalnav'>
// 	<div className="wrap gn-wrap">
// 		<Link to="/" className="title gn-title">Wayne C</Link>
// 		<NavItem href='/portfolio'>Portfolio</NavItem>
// 		<NavItem href='/contact'>Contact</NavItem>
// 	</div>
// </Navbar>
// {/* <Link to="/" className={classNames('title gn-title',fg)}> Wayne </Link> */}
// {/* <NavLinks id="default-nav" className={classNames('gn-list'+fg)} fg={fg} /> */}
// {/* <NavLinks id="burger-nav" className={'gn-list'+fg} fg={fg} include="home" /> */}
