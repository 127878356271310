import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SearchResult from './SearchResult';
import { Eyebrow } from '../../';

import { updateSuggestion } from '../../../modules/search';

class SearchResultGroup extends Component {
	// constructor(props){
	// 	super(props)
	// }

	// + Add an "s" if there are more than 1 result
	// let resultsLabel = "Result"
	// if (this.props.data.items.length > 1){
	// 	resultsLabel += "s"
	// }

	
	handleFocus = event => {
		const suggestion = {
			tag: this.props.data.name,
			count: this.props.data.items.length
		}

		this.props.updateSuggestion(suggestion)
	}

	render(){

		return(
			<li 
				className="gs-result-group"
				onFocus={this.handleFocus}
			>
			{/* Wrapped in link to focus properly */}
			<a className="gs-res-group-link" href="#!">
			
				<Eyebrow >
					<span className="gs-res-group-name">{`${this.props.data.name} `}</span>
					<span className="gs-res-group-count">
						{`${this.props.data.items.length}`}
					</span>
					{/* {`"${this.props.data.name}" Result${plurality} (${this.props.data.items.length})`} */}
				</Eyebrow>
				
				<ul className="gs-result-group-list">

					{this.props.data.items.map((item, index) => (
						<SearchResult
						{...item}
						key={'gs-result-' + index}
						/>
					))}

				</ul>

			</a>
		</li>			
		)
	}
}
// export default SearchResultGroup;

SearchResultGroup.propTypes = {
	data: PropTypes.object,
}

const mapStateToProps = state => ({
	// isSearching: state.search.isSearching,
	// query: state.search.query,
	// results: state.search.results,
})

const mapDispatchToProps = dispatch => bindActionCreators({
//   search,
	updateSuggestion,
}, dispatch)

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(SearchResultGroup)