import React, { Component } from 'react';
import { PhotoGalleryThumbnail } from '../';
import photoInfo from '../../data/photo_data';
import './PhotoGallery.scss';

class PhotoGallery extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className='photo-gallery-container gallery-grid'>
				{this.props.photos.map((imgName, index) => {
					let { title } = photoInfo[imgName];

					return (
						<PhotoGalleryThumbnail
							key={'photo-gallery-' + index}
							src={`https://s3-us-west-2.amazonaws.com/assets.che.ng/img/thumb/${imgName}.jpg`}
							alt={title}
							href={`/photo/${imgName}`}
						/>
					);
				})}
			</div>
		);
	}
}
export default PhotoGallery;

PhotoGallery.defaultProps = {
	photos : [],
};
