import React, { Component } from 'react';
// import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Header.scss';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		let { theme, headline, subheadline, className } = this.props;

		return (
			<header
				className={classNames('ws-header', className, theme)}
			>
				<div className="content">
					{headline && <h1 className="headline"> {headline} </h1>}
					{subheadline && <p className="subheadline"> {subheadline} </p>}
					{this.props.children}
				</div>
			</header>
		);
	}
}

export default Header;

Header.defaultProps = {
	// theme: "ws-dark-bluegrey",
	theme: ''
};

Header.propTypes = {
	theme: PropTypes.string,
	headline: PropTypes.string,
	subheadline: PropTypes.string
};
