import React from 'react';
import PropTypes from 'prop-types'

const Headline = props => {
	return (
		<h1 className="headline">
			{props.text}
			{props.children}
		</h1>
	);
};
export default Headline;

Headline.propTypes = {
	text: PropTypes.string,
}