import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {PageRoot, Nav, Footer, PhotoDisplay} from '../../components';
import './PhotoPage.scss';

class PhotoDisplayPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			imgID : '',
		};
	}

	componentDidMount = () => {
		const {imgID} = this.props.match.params;
		console.log('imgID:', imgID);

		this.setState({imgID});
		// this.tailor();
	};

	tailor = () => {
		const deviceHeight = window.screen.height;
		const deviceWidth = window.screen.width;
		const height = window.innerHeight;
		const width = window.innerWidth;
		const orientation = height > width ? 'portrait' : 'landscape';

		const updated = {
			deviceHeight,
			deviceWidth,
			height,
			width,
			orientation,
		};
		console.log('tailored:', updated);
		this.setState(updated);
		return updated;
	};

	// render //////////////////////////////////////////////////
	render() {
		const {imgID} = this.state;
		// return (
		return this.props.match.params.imgID ? (
			<PageRoot id='photo-root' page_title={imgID ? `photo - ${imgID}` : 'photo'}>
				<Nav back='transparent' front='white' />
				<main>
					{/* <article className='photo-display-article'> */}
						<PhotoDisplay imgID={imgID} />
					{/* </article> */}
				</main>
				<Footer
					style={{
						backgroundColor : 'transparent',
					}}
				/>
			</PageRoot>
		) : (
			<Redirect exact to='/photos' />
		);

		// );
	}
}
export default PhotoDisplayPage;
