import React from 'react';
import {
	PageRoot,
	Header,
	Footer,
	Section,
	Link,
	LinkCluster,
	Nav,
} from '../../components';
import codeGraphic from './graphic (code).svg';

const Landing = props => {
	return (
		<PageRoot id="landing-root" page_title="home">
			<Nav front="white" />
			<main className="">
				<Header headline="&darr;" className="fill-window" />
				<article className="page-content section-wrap">
					{/* Dev Section =============================== */}
					<Section
						id="dev-section"
						className="landing-section can-wrap dark-alt"
					>
						<div
							id="redacted-code-decor"
							className="subsection decor-container"
						>
							<img src={codeGraphic} alt="redacted-code" className="decor" />
						</div>
						<div className="subsection content-container">
							<div className="sec-content">
								<h3 className="sec-headline">
									{'<'}
									<span>WebDeveloper</span>
									{'/>'}
								</h3>
								<p className="sec-subheadline">
									Some copy about what I develop, why I develop / whatever.
									Lorem ipsum blah blah blah.
								</p>
								<Link to="/portfolio" className="ws-btn ws-primary c2a-btn">
									View Portfolio
								</Link>
							</div>
						</div>
					</Section>

					{/* Photo Section ============================= */}
					<Section
						id="photo-section"
						className="landing-section"
					>
						<div className="sec-content">
							<h3 className="sec-headline">Photography</h3>
							<p className="sec-subheadline eyebrow"> Fine-Art / Landscape </p>
							<Link to="/photography" className="ws-btn c2a-btn">
								View Gallery
							</Link>
						</div>
					</Section>

					{/* Contact Section =========================== */}
					<Section
						id="contact-section"
						className="landing-section"
					>
						<div className="section-body">
							<div className="subsection content-container">
								<div className="sec-content">
									<h3 className="sec-headline">Get in Touch</h3>
									<p className="sec-subheadline">
										The best way to contact me is through email, but you can
										also find me on all online platforms as
										<mark className="mark-yellow mark-on-hover">
											@wayncheng
										</mark>
									</p>
									<Link to="/contact" className="ws-btn ws-green c2a-btn">
										Contact
									</Link>
								</div>
							</div>
							<div className="subsection cluster-container">
								<LinkCluster />
							</div>
						</div>
					</Section>

					{/* =========================================== */}
				</article>
			</main>
			<Footer />
		</PageRoot>
	);
};
export default Landing;
