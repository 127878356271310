import React from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';


const NavLinks = props => {
	return (
		<ul {...props} className={classNames('nav-list', props.className)}>
			{props.include === 'home' && <NavLink {...props} to="/">Home</NavLink>}
			<NavLink {...props} to="/portfolio">Portfolio</NavLink>
			<NavLink {...props} to="/contact">Contact</NavLink>
			<NavLink {...props} to="/photos">Photo</NavLink>
		</ul>
	);
};
export default NavLinks;





export const NavLink = props => {
	return (
		<li className={classNames('nav-list-item', props.item_class)}>
			<Link className={classNames('nav-link', props.link_class)} to={props.to}>
				{props.text}
				{props.children}
			</Link>
		</li>
	);
};
NavLink.propTypes = {
	to         : PropTypes.string.isRequired,
	text       : PropTypes.string,
	item_class : PropTypes.string,
	link_class : PropTypes.string,
};