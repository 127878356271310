import React from 'react';
import './PortfolioSection.scss';

const PortfolioSection = props => {
	return (
		<section className={'portfolio-section content-section ' + props.className}>
			<div className="section-body">
				{/* <p className="eyebrow">{props.eyebrow}</p> */}
				<h2 className="headline">{props.headline}</h2>
				<p className="subheadline">{props.subheadline}</p>
				{/* <p className="detail position-detail">{props.position}</p> */}

				{props.contributions && (
					<ul className="contributions-list">
						{props.contributions.map((contribution, index) => (
							<li className="contribution" key={index}>
								{contribution}
							</li>
						))}
					</ul>
				)}

				{props.tags && (
					<ul className="tags-list">
						{props.tags.map((tag, index) => (
							<li className="tag" key={index}>
								{tag}
							</li>
						))}
					</ul>
				)}

				<div className="actions">
					<a href={props.repo} className="btn action-secondary repo-link">
						{' '}
						GitHub{' '}
					</a>
					<a href={props.demo} className="btn action-primary demo-link">
						{' '}
						{props.demo_text}{' '}
					</a>
				</div>
			</div>
			{props.children}

			{props.curtain_bottom && (
				<img
					src={props.curtain_bottom}
					className="curtain curtain-bottom"
					alt=""
					style={props.bottom_y && { transform: `translateY(${props.bottom_y}%)` }}
				/>
			)}
			{props.curtain_top && (
				<img
					src={props.curtain_top}
					className="curtain curtain-top"
					alt=""
					style={props.top_y && { transform: `translateY(${props.top_y}%)` }}
				/>
			)}
		</section>
	);
};

export default PortfolioSection;

PortfolioSection.defaultProps = {
	className: ''
};
