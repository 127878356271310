import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
// import { PortalWithState } from 'react-portal';
import { PortfolioPage } from '../pages';


class PortalCard extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			// <PortalWithState closeOnOutsideClick closeOnEsc>
			// 	{({ openPortal, closePortal, isOpen, portal }) => [
			// 		<button key="foo" onClick={openPortal}>
			// 			Open Portal
			// 		</button>,
			// 		portal(
			// 			<p>
			// 				This is more advanced Portal. It handles its own state.{' '}
			// 				<button onClick={closePortal}>Close me!</button>, hit ESC or click
			// 				outside of me.
			// 			</p>
			// 		)
			// 	]}
			// </PortalWithState>
			<div className="portal-container portal-card">
				<PortfolioPage/>
			</div>
		);
	}
}
export default PortalCard;
