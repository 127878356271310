import React from 'react';
import PropTypes from 'prop-types'

const Subeadline = props => {
	return (
		<h2 className="subheadline">
			{props.text}
			{props.children}
		</h2>
	);
};
export default Subeadline;

Subeadline.propTypes = {
	text: PropTypes.string,
}