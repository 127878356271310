


// export {default as links} from './links'
// export {default as projects} from './projects'
// export {default as resume} from './resume'
// export {default as photos} from './photo_data'


// import links from    './links.js'
// import projects from './projects.js'
// import resume from   './resume.js'
// import photos from   './photo_data.js'

export const links =    require('./links.js');
export const projects = require('./projects.js');
export const resume =   require('./resume.js');
export const photos =   require('./photo_data.js');

export default {
	links,
	projects,
	resume,
	photos,
}