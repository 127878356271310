import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import search from './search';
import modal from './modal';
import app from './app';

export default combineReducers({
	routing: routerReducer,
	search,
	modal,
	app,
})
