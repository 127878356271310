module.exports = {
	vrooms: {
		headline: "VRooms",
		subheadline: "In-Browser Virtual Reality for Real Estate",
		position: "A-Frame Lead Developer (Full-Stack)",
		contribution: [ ],
		repo: "https://github.com/wayncheng/VRooms",
		demo: "https://v-rooms.herokuapp.com/demo",
		demo_text: "Tour VRooms"
	},
	mypass: {
		headline: "MyPass",
		subheadline: "Biometric user authentication using face and voice identification. Powered by AWS Rekognition.",
		position: "Full-Stack Lead Developer, Project Manager",
		contribution: [ ],
		repo: "https://github.com/wayncheng/mypass",
		demo: "https://my-pass.herokuapp.com",
		demo_text: "Try MyPass"
	},
	jobba: {
		headline: "Jobba",
		subheadline: "The job portal to end all job portals. Aggregates listings from Indeed, Dice, Github Jobs, Linkup, Authentic Jobs, and uses Glassdoor for each company/salary info.",
		position: "Front-End Developer",
		contribution: [ ],
		repo: "https://github.com/wayncheng/jobba",
		demo: "https://wayncheng.github.io/jobba/",
		demo_text: "Search Jobba"
	},
	hangman: {
		headline: "Triple Crown Hangman",
		subheadline: "Triple-Crown themed version of Hangman. Win 4 straight games to win the Triple Crown! But don't take your victory lap just yet, each win increases the difficulty.",
		position: "Front-End Developer",
		contribution: [ ],
		repo: "https://github.com/wayncheng/hangman-game",
		demo: "https://wayncheng.github.io/hangman-game",
		demo_text: "Play Hangman"
	},
	hotdog: {
		headline: "Hotdog or Not",
		subheadline: "Simple web app to identify hotdogs in images. Inspired by HBO's Silicon Valley.",
		position: "Full-Stack Developer",
		contribution: [ ],
		repo: "https://github.com/wayncheng/hotdog-or-not",
		demo: "https://hotdog-or-not.herokuapp.com",
		demo_text: "Examine Hotdogs"
	},
	amnesia: {
		headline: "Amnesia",
		subheadline: "Real-time card game for groups to play on their own devices. Built with Socket.IO",
		eyebrow: "Real-Time Gaming",
		contributions: [],
		tags: ["Node.js","Express","Socket.IO","WebSockets","React"],
		position: "Full-Stack Developer",
		repo: "https://github.com/wayncheng/waynomia",
		demo: "https://amnesia-game.herokuapp.com",
		demo_text: "Play Amnesia"
	},
	tft: {
		headline: "TFT Items",
		subheadline: "Item combination finder for Teamfight Tactics",
		eyebrow: "Gaming Tool",
		contributions: [],
		tags: ["React","Redux"],
		position: "Front-End Developer",
		repo: "https://github.com/wayncheng/tft",
		demo: "https://wayncheng.github.io/tft",
		demo_text: "Use Tool"
	},
	hanabi: {
		repo: "https://github.com/wayncheng/hanabi-tracker",
		demo: "https://wayncheng.github.io/hanabi-tracker/",
		demo_text: "Track Hanabi" 
	}
}