import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

const QuerySuggestion = props => {

	// let suggestionText = `${props.suggestion.tag} (${props.suggestion.count})`;
	let suggestionText = props.suggestion.tag;
		
		return(
			<span className="query-suggestion">
				{ (props.suggestion.tag !=="" && props.suggestion.count >= "") && (
					suggestionText
				)}
			</span>
		)
}

const mapStateToProps = state => ({
	suggestion: state.search.suggestion,
})

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch)

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(QuerySuggestion)
