import React from 'react';
import {
	PageRoot,
	// Header,
	Eyebrow,
	Nav,
} from '../../components';

const SiteCredits = props => {
	return (
		<PageRoot id="site-credits-root" className="info-root" page_title="credits">
			<Nav/>
			<main className="expand-and-center">
				<Eyebrow>Credits</Eyebrow>
				<h3 className="site-title headline">che.ng</h3>

				<table>
					<tbody>
						<tr>
							<td>Developed by</td>
							<td>Wayne Cheng</td>
						</tr>
						<tr>
							<td>Designed by</td>
							<td>Wayne Cheng</td>
						</tr>
					</tbody>
				</table>
			</main>
		</PageRoot>
	);
};
export default SiteCredits;
