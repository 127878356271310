import React from 'react';
import resumeData from '../../data/resume.js';
import { Level } from '../../components'

const ResumeHeader = props => {
	let { name, phone, email, location } = resumeData.info.personal;
	let { GitHub, LinkedIn, Portfolio } = resumeData.info.links;
	return (
		// <header className="resume-header hide-on-links-only">
		<header className="resume-header" id="resume-header">
			<h2 className="headline">{name}</h2>
			<Level className="with-dividers collapse-mid" htmlTag="ul">
				<li className="info-email">{email}</li>
				<li className="info-phone">{phone}</li>
				<li className="info-location">{location}</li>
			</Level>
			<Level className="with-dividers resume-info-links">
				<a href={GitHub} alt="Wayne's GitHub" className="resume-link" id="resume-info-link-github">
					GitHub
				</a>
				<a href={LinkedIn} alt="Wayne's LinkedIn Profile" className="resume-link" id="resume-info-link-linkedin">
					LinkedIn
				</a>
				<a href={Portfolio} alt="Wayne's Development Portfolio" className="resume-link" id="resume-info-link-portfolio">
					Portfolio
				</a>
			</Level>
		</header>
	);
};
export default ResumeHeader