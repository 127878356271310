import React, { Component } from "react";
import { SearchContainer, PageRoot } from "../components";

class SearchPage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	// render //////////////////////////////////////////////////
	render() {
		return (
			<PageRoot id="search-root" page_title="search">
				<main>
					<SearchContainer/>
				</main>
			</PageRoot>
		);
	}
}
export default SearchPage;
