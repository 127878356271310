import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Redirect } from 'react-router-dom'

class AirTrafficControl extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirect: true,
		};
	}

	componentDidMount = () => {
		console.log(`---> Air Traffic Control ---> ${JSON.stringify(this.props,null,2)}`)
	}

	setRedirect = () => {
    this.setState({
      redirect: true
    })
  }
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/target' />
    }
  }

	render() {
		return (
			<span
				{...this.props}
				className={classNames('air-traffic-control',this.props.className)}
			>
				{this.props.children}
			</span>
		);
	}
}

////////////////////////////////////////////////////
AirTrafficControl.defaultProps = {
	// id : '',
};
AirTrafficControl.propTypes = {
	id : PropTypes.string,
};

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AirTrafficControl);
