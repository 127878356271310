import React from 'react';

const Level = props => {
	if (props.htmlTag === 'ul') {
		return <ul className={'ws-level ' + props.className}>{props.children}</ul>;
	} else {
		return (
			<div className={'ws-level ' + props.className}>{props.children}</div>
		);
	}
};
export default Level;
