import React from 'react';
// import Waldo from './waldo.svg';
import {
	Nav,
	PageRoot,
	Headline,
	Eyebrow,
	// Subheadline,
	Footer,
} from '../../../components';

const Error404 = ({ location }) => {
	return (
		<PageRoot id="error404-root" className="flex-center" page_title="404" >
			<Nav back="transparent" front="dark"/>
			<main className="expand-and-center">
				<div className="page-focus">
					<Eyebrow>Error 404</Eyebrow>
					<Headline>
						Sorry, couldn't find 
						<span> {location.pathname} </span>
					</Headline>
					{/* <Subheadline>
						But on the bright side, you found Waldo <span className="pop" >No.404!</span>
					</Subheadline> */}
				</div>
				{/* <img src={Waldo} alt="waldo" id="waldo-img"/> */}
				{/* <Waldo id="waldo-img" /> */}
			</main>
			<Footer/>
		</PageRoot>
	)
}
export default Error404;