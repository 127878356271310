import React, { Component } from 'react';
import { PageRoot, Nav, PortfolioSection } from '../../components';
import { resume } from '../../data/index';
import './PortfolioPageNeue.scss';
import waveBottomSVG from '../../assets/wave-bottom-white.svg';
// import waveTopSVG from '../assets/wave-top-white.svg';
import Choreographer from 'choreographer-js';

class PortfolioPage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount = () => {
		//==================================================
		// window.addEventListener('scroll', (event) => {
		// 	// console.log('event:',event)
		// 	console.log('window.scrollY:',window.scrollY)
		// })

		//==================================================

		// let last_known_scroll_position = 0;
		// let ticking = false;

		// function doSomething(scroll_pos) {
		// 	// Do something with the scroll position
		// 	// console.log('window.scrollY:',window.scrollY)
		// 	console.log('scroll_pos:',scroll_pos)
		// }

		// window.addEventListener('scroll', function(e) {
		// last_known_scroll_position = window.scrollY;

		// if (!ticking) {
		// 	window.requestAnimationFrame(function() {
		// 		doSomething(last_known_scroll_position);
		// 		ticking = false;
		// 	});

		// 	ticking = true;
		// }
		// });
		//==================================================
		const choreographer = new Choreographer({
			animations: [
				{
					range: [-1, window.innerHeight],
					selector: '#portfolio-header',
					type: 'scale',
					style: 'opacity',
					from: 1,
					to: 0.7,
				},
			],
		});
		window.addEventListener('scroll', function() {
			choreographer.runAnimationsAt(window.pageYOffset);
		});
	};

	handleScroll = event => {
		const y = window.scrollY;
		console.log('y:', y);
	};

	render() {
		// const projectList = ['vrooms', 'mypass', 'jobba', 'amnesia', 'hotdog'];
		const { vrooms, mypass, jobba, amnesia, hotdog } = resume.projects;

		return (
			<PageRoot
				page_title="portfolio"
				className="portfolio-root"
				// onScroll={this.handleScroll}
			>
				<Nav back="transparent" front="white" />

				<header id="portfolio-header" className="hero">
					<h1 className="headline">Wayne's Dev Portfolio</h1>
					<img src={waveBottomSVG} className="curtain curtain-bottom" alt="" />
				</header>
				{/* {projectList.map((projectID, index) => {
					const project = resume.projects[projectID];
					let sectionClass = '';
					if (project)
					return (
						<PortfolioSection
							// headline={project.headline}
							// subheadline={project.subheadline}
							// contributions={projects.contributions}
							// position={project.position}
							{...project}
							key={index}
							className={sectionClass}
						/>
					);
				})} */}
				<PortfolioSection
					{...vrooms}
					className="sec-vrooms curtain-shrink"
					// curtain_bottom={waveTopSVG} bottom_y={90}
				/>
				<PortfolioSection {...mypass} className="sec-mypass dark-theme curtain-expand">
					{/* <img src={waveTopSVG} className="curtain curtain-top" alt=""/> */}
					{/* <img src={waveBottomSVG} className="curtain curtain-bottom" alt=""/> */}
				</PortfolioSection>
				<PortfolioSection
					{...jobba}
					className="sec-jobba"
					// curtain_top={waveBottomSVG} top_y={-90}
				/>
				<PortfolioSection {...amnesia} className="sec-amnesia light-theme" />
				<PortfolioSection {...hotdog} className="sec-hotdog" />
			</PageRoot>
		);
	}
}
export default PortfolioPage;
