import React, {Component} from 'react';
import {Route, BrowserRouter, Switch, Redirect} from 'react-router-dom';
import {
	LandingPage,
	// LandingMinimalPage,
	LandingOldPage,
	LandingPortalsPage,
	// TemporaryLandingPage,
	PortfolioPage,
	ContactPage,
	// PhotographyPage_OLD,
	PhotographyFluidPage,
	ResumePage,
	Error404,
	SearchPage,
	SandboxPage,
	SiteInfo,
	SiteCredits,
	MetroPage,
	PortfolioPageNeue,
	PhotoPage,
} from './pages';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {
	// setMetadata,
	setTheme,
} from './modules/app';
import './config/custom-bootstrap.scss';
import './style/scss/waynestrap.scss';
import './style/scss/app.scss';
import './config/scss/utilities.scss';
import './App.scss';
import {AirTrafficControl} from './components';

class App extends Component {
	componentDidMount = () => {
		// this.props.setMetadata({
		// 	version: 6.9,
		// 	last_updated: 'long time ago'
		// })

		// Dark / Light Mode ====================================================================
		// - Dark mode is the default mode and will be used more often.
		// - If client OS preference is dark, it will always be dark mode, no matter the time.
		// - If client OS preference is light or N/A, dark/light mode depends on time of day.
		// - Nightime is from 6PM to 7AM
		//---------------------------------------------------------------------------------------
		this.props.setTheme();
	};

	render() {
		return (
			<BrowserRouter forceRefresh>
				<Switch>
					{/* Experimental */}
					{process.env.NODE_ENV !== 'production' && (
						<React.Fragment>
							<Route path='/atc' render={props => <AirTrafficControl {...props} />} />

							<Route exact path='/metro' component={MetroPage} />
							<Route exact path='/portals' component={LandingPortalsPage} />
							<Route exact path='/sandbox' component={SandboxPage} />
							<Route exact path='/sandbox/portfolio' component={PortfolioPageNeue} />
							<Route exact path='/search' component={SearchPage} />
						</React.Fragment>
					)}

					<Route exact path='/site/info' component={SiteInfo} />
					<Route exact path='/site/credits' component={SiteCredits} />

					<Route exact path='/photo/:imgID?' component={PhotoPage} />
					<Route exact path='/photos' component={PhotographyFluidPage} />
					<Redirect exact from='/photography' to='/photos' />

					<Route exact path='/resume/:view?' component={ResumePage} />
					<Route exact path='/contact' component={ContactPage} />
					<Route exact path='/portfolio' component={PortfolioPage} />

					<Route exact path='/home' component={LandingPage} />
					<Route exact path='/' component={LandingOldPage} />

					<Route component={Error404} />
				</Switch>
			</BrowserRouter>
		);
	}

	// render() {
	// 	return (
	// 		<BrowserRouter forceRefresh>
	// 			<Switch>
	// 				{/* Experimental */}
	// 				{/* <Route
	// 					path='/'
	// 					render={props => {
	// 						console.log('props:', props);
	// 						return null;
	// 					}}
	// 				/> */}
	// 				<Route
	// 					path={'/'}
	// 					children={({match}) => {
	// 						console.log('match:', match);
	// 						return <Route exact path='/' component={LandingOldPage} />;
	// 					}}
	// 				/>
	// 				<Route path='/atc' render={props => <AirTrafficControl {...props} />} />

	// 				<Route exact path='/metro' component={MetroPage} />
	// 				<Route exact path='/portals' component={LandingPortalsPage} />
	// 				<Route exact path='/sandbox' component={SandboxPage} />
	// 				<Route exact path='/sandbox/portfolio' component={PortfolioPageNeue} />
	// 				<Route exact path='/search' component={SearchPage} />

	// 				<Route exact path='/site/info' component={SiteInfo} />
	// 				<Route exact path='/site/credits' component={SiteCredits} />

	// 				<Route exact path='/photo/:imgID?' component={PhotoPage} />
	// 				<Route exact path='/photos' component={PhotographyFluidPage} />
	// 				<Redirect exact from='/photography' to='/photos' />

	// 				<Route exact path='/resume/:view?' component={ResumePage} />
	// 				<Route exact path='/contact' component={ContactPage} />
	// 				<Route exact path='/portfolio' component={PortfolioPage} />

	// 				<Route exact path='/home' component={LandingPage} />
	// 				<Route exact path='/' component={LandingOldPage} />

	// 				<Route component={Error404} />
	// 			</Switch>
	// 		</BrowserRouter>
	// 	);
	// }
}
// export default App;

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			// setMetadata,
			setTheme,
		},
		dispatch,
	);
export default connect(mapStateToProps, mapDispatchToProps)(App);
