import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
	PageRoot,
	Nav,
	// Modal,
	// Level,
	// SearchContainer,
	// ProgressivePhoto,
	// PhotoSwipeGallery,
	PhotoGallery,
	TestArticle,
	TestArticleMinimal,
} from '../../components';
import { openModal } from '../../modules/modal';
// import blurFile from '../../assets/cross-blur.jpg';
// import fullFile from '../../assets/cross-2k.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import TestArticle from './TestArticle';

class SandboxPage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	handleModalTrigger = (event) => {
		event.preventDefault();

		let modal_id = event.target.getAttribute('modal_id');
		console.log('... opening modal_id:', modal_id);

		this.props.openModal(modal_id);
	};

	// render //////////////////////////////////////////////////
	render() {
		// const srcDir = '../assets'
		return (
			<PageRoot id='sandbox-root' page_title='sandbox'>
				<Nav front='black' back='white' />
				<main
					style={{
						// backgroundColor : '#ffffff',
						paddingTop      : '4rem',
					}}
				>
					<h1 className="text-6xl">Sandbox</h1>
					<section>
						<PhotoGallery
							photos={[
								// tier 1
								'7209',
								'0755',
								'6993',
								// tier 2
								'0549',
								'7383',
								'7680',
								// tier 3+
								'0037',
								'0828',
								'2277',
								'2396',
								'2725',
								'3176',
								'6274',
								'6888',
								'6924',
								'7846',
								'D0017',
								'D0271',
							]}
						/>
						{/* <PhotoSwipeGallery
							location={this.props.history.location}
							gallery_id={'portfolio'}
							photos={[
								// tier 1
								'7209',
								'0755',
								'6993',
								// tier 2
								'0549',
								'7383',
								'7680',
								// tier 3+
								'0037',
								'0828',
								'2277',
								'2396',
								'2725',
								'3176',
								'6274',
								'6888',
								'6924',
								'7846',
								'D0017',
								'D0271',
							]}
						/> */}
					</section>
					<section>
						<FontAwesomeIcon icon={[ 'fab', 'github' ]} />
					</section>
					<TestArticleMinimal/>
					<TestArticle/>
					{/* <section>
					<ProgressivePhoto
						// blur={srcDir+'/hood-blur.jpg'}
						blur={blurFile}
						full={fullFile}
						container_style={{
							width  : '100%',
							height : '120vh',
						}}
					/>
				</section> */}

					{/* <SearchContainer /> */}
				</main>
			</PageRoot>
		);
	}
}

const mapStateToProps = (state) => ({
	isOpen : state.modal.isOpen,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			openModal,
		},
		dispatch,
	);

export default connect(mapStateToProps, mapDispatchToProps)(SandboxPage);
