import React from 'react'
import search_icon from './search_icon.svg';

const SubmitSearchButton = props => {
	return (
		<button 
			{...props}
			type="submit" 
			className="search-btn" 
			id="submit-search"
		>
			<img className="icon" src={search_icon} alt="Search Wayne's Website"/>
		</button>
	)
}
export default SubmitSearchButton;

