import React from 'react';
import {
	PageRoot,
	Eyebrow,
	Footer,
	Nav,
} from '../../components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import infoJSON from '../../../package.json';
// import infoJSON from '../../../public/manifest.json';

const SiteInfo = props => {
	return (
		<PageRoot id="site-info-root" className="info-root" page_title="site info">
			<Nav/>
			<main className="expand-and-center">
				<Eyebrow>Site Information</Eyebrow>
				<h3 className="site-title headline">che.ng</h3>
				
				<table>
					<tbody>
						<tr>
							<td>Version</td>
							<td>{infoJSON.version}</td>
							{/* <td>{props.metadata.version}</td> */}
						</tr>
						<tr>
							<td>Last Updated</td>
							<td>{infoJSON.last_updated}</td>
							{/* <td>{props.metadata.last_updated}</td> */}
						</tr>
						{/* <tr>
							<td>License</td>
							<td>{infoJSON.license}</td>
						</tr> */}
						<tr>
							<td>Designed by</td>
							<td>Wayne Cheng</td>
						</tr>
						<tr>
							<td>Developed by</td>
							<td>Wayne Cheng</td>
						</tr>
					</tbody>
				</table>

				{/* <ul> */}
					{/* <li>
						<span>Version</span>
						{packageJSON.version}
					</li>
					<li>
						<span>Last Updated</span>
						{packageJSON.last_updated}
					</li> */}
					{/* <li> 
						<span> Designed by </span> Wayne Cheng 
					</li>
					<li> 
						<span> Developed by </span> Wayne Cheng 
					</li> */}
				{/* </ul> */}
			</main>
			<Footer/>
		</PageRoot>
	);
};
// export default SiteInfo;
const mapStateToProps = state => ({
	metadata: state.app.metadata,
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(SiteInfo)
