import React from 'react'
import classNames from 'classnames'

const Section = props => {
	return(
		<section {...props} id={props.id && props.id} className={classNames('ws-section',props.className)}>
			{props.children}
		</section>
	)
}
export default Section
