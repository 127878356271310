import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PhotoSwipeGallery } from 'react-photoswipe';
import '../../style/libs/react-photoswipe/photoswipe.css';
import './PhotoGrid.scss';

import photoInfo from '../../data/photo_data';

class PhotoGrid extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// gid    : '',
			// pid    : '',
			// isOpen : false,
		};
	}
	////////////////////////////////////////////////////
	// componentDidMount ===============================
	componentDidMount = () => {
		// this.openFromUrl();
	};

	// openFromUrl = () => {
	// 	// http://localhost:3000/photo#&gid=0&pid=10
	// 	const { location } = this.props;
	// 	const { hash } = location;
	// 	if (hash.length > 0) {
	// 		const queries = hash.split('&');
	// 		let gid;
	// 		let pid;

	// 		for (let i = 0; i < queries.length; i++) {
	// 			const query = queries[i];
	// 			if (query.indexOf('gid=') === 0) gid = query.substr(4);
	// 			if (query.indexOf('pid=') === 0) pid = query.substr(4);
	// 		}
	// 		console.log(`gallery: ${gid} / photo: ${pid}`);

	// 		// const photo_id = `img-${gid}-${pid}`;
	// 		// this.clickThumbnail(photo_id);

	// 		// this.setState({
	// 		// 	gid,
	// 		// 	pid,
	// 		// 	isOpen: (gid && pid) ? true : false,
	// 		// });
	// 	}
	// };

	// clickThumbnail = (photo_id) => {
	// 	console.log(`CLICK THUMBNAIL ---> ${photo_id}`);
	// 	const $img = document.getElementById(photo_id);
		
	// 	// const eventObj = document.createEvent('Events');
	// 	// eventObj.initEvent('click', true, false);
	// 	// $img.dispatchEvent(eventObj);
		
	// 	const $thumb = $img.parentNode;
	// 	console.log('$thumb:',$thumb);

	// 	$img.dispatchEvent(
   //    new MouseEvent('click', {
	// 				view: window,
	// 				defaultPrevented: true,
   //        bubbles: true,
   //        cancelable: true,
   //        buttons: 1
   //    })
   //  )
	// };
	// handleThumbnailClick = event => {
	// 	event.preventDefault();
	// 	const {id} = event.target;
	// 	this.clickThumbnail(id);
	// }

	getThumbnailContent = (item) => {
		return (
			<img
				src={item.thumbnail}
				alt={item.caption}
				id={`img-${item.gid}-${item.pid}`}
				data-gid={item.gid}
				data-pid={item.pid}
				// onClick={this.handleThumbnailClick}
			/>
		);
	};



//////////////////////////////////////////////////////////////////////////////////
	render() {
		const items = this.props.photos.map((file, i) => {
			let { width, height, title, caption } = photoInfo[file];
			let thumbLocation = 'https://s3-us-west-2.amazonaws.com/assets.che.ng/img/thumb/';
			if (i < 6) {
				thumbLocation = `preloaded/`;
			}

			return {
				src       : `https://s3-us-west-2.amazonaws.com/assets.che.ng/img/2k/${file}.jpg`,
				thumbnail : thumbLocation + file + '.jpg',
				w:width,
				h: height,
				title,
				caption,
				index     : i,
				gid       : this.props.gallery_id,
				pid       : file,
			};
		});

		const options = {
			preload    : [ 1, 3 ], // lazy load 1 photo before and 2 photos after current
			galleryUID : this.props.gallery_id,
		};

		return (
			<div id='photoswipe-root'>
				<PhotoSwipeGallery
					items={items}
					options={options}
					thumbnailContent={this.getThumbnailContent}
				/>
			</div>
		);
	}
//////////////////////////////////////////////////////////////////////////////////
}

export default PhotoGrid;

PhotoGrid.defaultProps = {
	// photos: ["7209", "0037"],
	photos     : [],
	gallery_id : 0,
};
PhotoGrid.propTypes = {
	photos : PropTypes.arrayOf(PropTypes.string),
};
