module.exports = {
	projects: {
		vrooms: {
			repo: "https://github.com/wayncheng/VRooms",
			demo: "https://v-rooms.herokuapp.com/demo"
		},
		mypass: {
			repo: "https://github.com/wayncheng/mypass",
			demo: "https://my-pass.herokuapp.com",
			// urls: [
			// 	{
			// 		text: "GitHub",
			// 		to: "https://github.com/wayncheng/mypass",
			// 		type: "beta"
			// 	},
			// 	{
			// 		text: "Try",
			// 		to: "https://my-pass.herokuapp.com",
			// 		type: "alpha"
			// 	}
			// ]
		},
		jobba: {
			repo: "https://github.com/wayncheng/jobba",
			demo: "https://wayncheng.github.io/jobba/"
		},
		hangman: {
			repo: "https://github.com/wayncheng/hangman-game",
			demo: "https://wayncheng.github.io/hangman-game"
		},
		hotdog: {
			repo: "https://github.com/wayncheng/hotdog-or-not",
			demo: "https://hotdog-or-not.herokuapp.com"
		}
	},
	website: "https://che.ng",
	github: "https://github.com/wayncheng",
	gitlab: "https://gitlab.com/wayn",
	linkedin: "https://linkedin.com/in/wayncheng",
	instagram: "https://www.instagram.com/wayncheng",
	twitter: "https://twitter.com/wayncheng",
	"500px": "https://500px.com/wayncheng",
	pinterest: "https://www.pinterest.com/wayncheng",
	resume_gdoc: "https://docs.google.com/document/d/1kfIvv2H7FTq4vBIxFZKgfDtO_k9ZL3esi0UaVdJgLO8",
	dribbble: "https://dribbble.com/wayn",
	medium: "https://medium.com/@wayncheng",
	etsy: "https://www.etsy.com/people/wcheng93",
	etsy_shop: "https://www.etsy.com/shop/waynechengphotos",
	facebook: "https://www.facebook.com/wayncheng/",
	stackoverflow: "https://stackoverflow.com/users/7809236/wayne",
	mailto: "mailto:wayne@che.ng",
}