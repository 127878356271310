import React from 'react';

// const data = [
// 	"Adapted A-Frame for use with React.",
// 	"Created A-Frame environment used in all rooms.",
// 	"Designed and developed annotation creation system within A-Frame.",
// 	"In-depth research of A-Frame's core technology and related applications."
// ]

const ResListBullets = props => {
	return (
		<ul>
			{props.data.map( (item,index) => {
				return (
					<li key={'li-'+index} >{item}</li>
				)
			})}
		</ul>
	);
};
export default ResListBullets;
