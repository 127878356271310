import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from '../../';

const SearchResult = props => (
	<li {...props} className={classnames('gs-result-item',props.className)}>
		<Link to={props.url}>
			{/* <div className="icon-container">
				<i className={props.icon}></i>
			</div> */}
			<div className="result-item-body">
				<h5 className="title">{props.title}</h5>
				<p className="subtitle"> {props.subtitle} </p>
			</div>
			<div className="arrow-icon-container icon-container">
				<i className="fas fa-chevron-right"></i>
			</div>
		</Link>
	</li>
)

export default SearchResult;

SearchResult.propTypes = {
	type: PropTypes.string,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	url: PropTypes.string,
}