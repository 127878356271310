import React from 'react';

const ResumeSectionContainer = props => {
	return (
		<section className={'section-container ' + props.className} id={props.id}>
			<h3 className="eyebrow section-label">{props.label}</h3>
			{props.children}
		</section>
	);
};
export default ResumeSectionContainer;
