import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';

class Link extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		if (this.props.to[0] === '/') {
			return (
				<RouterLink to={this.props.to} {...this.props}>
					{this.props.children}
				</RouterLink>
			);
		} else {
			return (
				<a href={this.props.to} {...this.props}>
					{this.props.children}
				</a>
			);
		}
	}
}
export default Link;
