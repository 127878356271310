import React from 'react';
import PropTypes from 'prop-types';
// import SearchResult from './SearchResult';
// import Eyebrow from '../../elements/text/Eyebrow';
import SearchResultGroup from './SearchResultGroup';

const SearchResultList = props => {
	return(
		<div className="gs-result-list-container">

			{/* Only show results if there are results to show */}
			{props.results.length >= 1 && (
				<div className="container-liner">
					{/* <Eyebrow>Results</Eyebrow> */}
					<ul className="gs-result-list">

						{/* For each result group... */}
						{props.matches.map((resultGroup, index) => (
							<SearchResultGroup data={resultGroup} key={'gs-result-group-' + index} />
						))}


					</ul>
				</div>
			)}
			
		</div>
	)
}
export default SearchResultList;

SearchResultList.propTypes = {
	results: PropTypes.array,
	// results: PropTypes.object,
}