import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Card.scss';
// import {Badge} from '../';

class Card extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	handleJaw = event => {
		event.preventDefault();
		let parent = event.target.parentNode.parentNode.parentNode;
		parent.classList.toggle('open-jaw');
	};

	render() {
		let {
			theme,
			title,
			subtitle,
			// leftLink,
			// rightLink,
			actions,
			children,
			// badge,
		} = this.props;

		let hasChildren = '';
		if (!children) hasChildren = 'barren';

		return (
			<div className={classNames('ws-card', theme, hasChildren)}>
				<div className='content'>
					{/* <div className='flex flex-row flex-wrap items-center justify-start mb-4'> */}
					{/* <div className='card-title-wrap mb-4'> */}
						<h3 className='card-title mb-4'>{title}</h3>
						{/* {badge && <Badge text={badge.text} type={badge.type} />}
					</div> */}
					<p className='card-subtitle'> {subtitle} </p>
				</div>

				{this.props.children}

				{actions && (
					<div className={'actions'}>
						<div />
						<div>
							{actions.map((ea, index) => {
								return (
									<a className={'ws-action-btn ' + ea.type} href={ea.to} key={index}>
										{ea.text}
									</a>
								);
							})}
							{/* <a href={leftLink.to}> {leftLink.text} </a> */}
							{/* <a href={rightLink.to}> {rightLink.text} </a> */}
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default Card;

Card.defaultProps = {
	// theme: "ws-dark-bluegrey",
	theme   : '',
	actions : null,
};

Card.propTypes = {
	theme    : PropTypes.string,
	title    : PropTypes.string,
	subtitle : PropTypes.string,
	actions  : PropTypes.arrayOf(PropTypes.object),
	badge    : PropTypes.object,
};
