// import links    from '../../../data/links.js';
// import projects from '../../../data/projects.js';
const links = require('../../../data/links.js');
const projects = require('../../../data/projects.js');

const devTags = [
	'development',
	'code',
	'portfolio',
	'web',
	'javascript',
	'work',
	'projects',
	'applications',
	'developer',
	'github'
];

// * Sample Result Item
// 	
//	{
//	 	name: "portfolio",
// 		tags: [ 'development', 'code', 'projects' 'github', ],
//	 	type: "internal",
//	 	url: "/portfolio",
//	 	title: "Web Development Portfolio",
//		subtitle: "View Wayne's web development projects"
//	}

const search_index = [
//==================================================
// Pages (internal)
	{ // > portfolio
		name: "dev_portfolio", 
		tags: devTags,
		type: "internal",
		url: "/portfolio",
		title: "Web Development Portfolio",
		subtitle: "View Wayne's web development projects"
	},
	{ // > photography
		name: "photography", 
		tags: ['portfolio', 'photography','pictures','art','camera', "album","gallery"],
		type: "internal",
		url: "/photography",
		title: "Photography Portfolio",
		subtitle: "Check out photographs taken by Wayne"
	},
	{ // > resume
		name: "resume", 
		tags: devTags,
		type: "internal",
		url: "/resume",
		title: "Full-Stack Developer Resume",
		subtitle: "View online version of Wayne's resume"
	},
	{ // > contact
		name: "contact", 
		tags: ['email','information','social media'],
		type: "internal",
		url: "/contact",
		title: "Contact Info",
		subtitle: "How to contact me and where to follow me"
	},

	// Social, External ..................................
	{ // > github
		name: "github", 
		tags: devTags.concat(['profile']),
		type: "repo",
		url: links.github,
		title: "GitHub (@wayncheng)",
		subtitle: "Overview of Wayne's repositories on GitHub"
	},
	{ // > linkedin
		name: "linkedin", 
		tags: ["linkedin","contact","resume","social","profile","connect"],
		type: "link_social",
		url: links.linkedin,
		title: "LinkedIn",
		subtitle: "Connect with me on LinkedIn"
	},
	{ // > instagram 
		name: "instagram", 
		tags: ["instagram","contact","social","profile","media","photography","pictures","art"],
		type: "link_social",
		url: links.instagram,
		title: "Instagram (@wayncheng)",
		subtitle: "Go to Wayne's Instagram profile"
	},
	{ // > twitter
		name: "twitter", 
		tags: ["twitter","contact","social","profile","media"],
		type: "link_social",
		url: links.twitter,
		title: "Twitter (@wayncheng)",
		subtitle: "Go to Wayne's Twitter profile"
	},
	{ // > 500px
		name: "500px", 
		tags: ["500px","contact","social","profile","media","art", "photography", "pictures", "album","gallery"],
		type: "link_social",
		url: links["500px"],
		title: "500px (@wayncheng)",
		subtitle: "Go to Wayne's 500px profile"
	},

	// Projects ..................................
	// > Jobba
	{
		name: "jobba_repo", 
		tags: ["jobba", "github", "source"],
		type: "repo",
		url: projects.jobba.repo,
		title: "Jobba Source Code on GitHub",
		subtitle: "Go to the GitHub repository for project Jobba"
	},
	{
		name: "jobba_demo", 
		tags: ["jobba", "demo"],
		type: "demo",
		url: projects.jobba.demo,
		title: "Jobba Demo",
		subtitle: "Try out Jobba"
	},
	// > MyPass
	{
		name: "mypass_repo", 
		tags: ["mypass", "github", "source"],
		type: "repo",
		url: projects.mypass.repo,
		title: "MyPass Source Code on GitHub",
		subtitle: "Go to the GitHub repository for project MyPass"
	},
	{
		name: "mypass_demo", 
		tags: ["mypass", "demo"],
		type: "demo",
		url: projects.mypass.demo,
		title: "MyPass Demo",
		subtitle: "Try out MyPass"
	},
	// > VRooms
	{
		name: "vrooms_repo", 
		tags: ["vrooms", "github", "source"],
		type: "repo",
		url: projects.vrooms.repo,
		title: "VRooms Source Code on GitHub",
		subtitle: "Go to the GitHub repository for project VRooms"
	},
	{
		name: "vrooms_demo", 
		tags: ["vrooms", "demo"],
		type: "demo",
		url: projects.vrooms.demo,
		title: "VRooms Demo",
		subtitle: "Try out VRooms"
	},
	// > Amnesia
	{
		name: "amnesia_repo", 
		tags: ["Amnesia", "github", "source"],
		type: "repo",
		url: projects.amnesia.repo,
		title: "Amnesia Source Code on GitHub",
		subtitle: "Go to the GitHub repository for project Amnesia"
	},
	{
		name: "amnesia_demo", 
		tags: ["amnesia", "demo"],
		type: "demo",
		url: projects.amnesia.demo,
		title: "Amnesia Demo",
		subtitle: "Try out Amnesia"
	},
	// > Hangman
	{
		name: "hangman_repo", 
		tags: ["hangman", "github", "source"],
		type: "repo",
		url: projects.hangman.repo,
		title: "Triple-Crown Hangman Source Code on GitHub",
		subtitle: "Go to the GitHub repository for project Triple-Crown Hangman"
	},
	{
		name: "hangman_demo", 
		tags: ["hangman", "demo"],
		type: "demo",
		url: projects.hangman.demo,
		title: "Triple-Crown Hangman Demo",
		subtitle: "Try out Triple-Crown Hangman"
	},
	// > Hotdog or Not
	{
		name: "hotdog_repo", 
		tags: ["hotdog", "github", "source"],
		type: "repo",
		url: projects.hotdog.repo,
		title: "Hotdog or Not Source Code on GitHub",
		subtitle: "Go to the GitHub repository for project Hotdog or Not"
	},
	{
		name: "hotdog_demo", 
		tags: ["hotdog", "demo"],
		type: "demo",
		url: projects.hotdog.demo,
		title: "Hotdog or Not Demo",
		subtitle: "Try out Hotdog or Not"
	},
	// ...........................................
	
	// > email (mailto)
	{
		name: "email_action", 
		tags: ["email", "mail", "contact", "address"],
		type: "action",
		url: "mailto:wayne@che.ng",
		title: "Send an Email to Wayne (wayne@che.ng)",
		subtitle: "Open in your mail client"
	},
	{
		name: "email_info", 
		tags: ["email", "mail", "contact", "address"],
		type: "action",
		url: "#!",
		title: "Email Address (wayne@che.ng)",
		subtitle: "Copy Wayne's email address to your clipboard"
	},

	// ...........................................
]

export default search_index;