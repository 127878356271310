import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
// import { search } from '../../../modules/search';
import SubmitSearchButton from './SubmitSearchButton';
import SearchInput from './SearchInput';
// import QuerySuggestion from './QuerySuggestion';


class SearchForm extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	handleSearch = event => {
		event.preventDefault();

		this.props.search(this.props.query)
	}

	render() {
		return (
			<form className="gs-form" autoComplete="off" onSubmit={this.handleSearch}>
				<SearchInput />
				<SubmitSearchButton
					// tabIndex = { (this.props.results.length > 0) ? "-1" : "1" }
					tabIndex="-1"
				/>
			</form>
		)
	}
}

const mapStateToProps = state => ({
	isSearching: state.search.isSearching,
	query: state.search.query,
	results: state.search.results,
})

const mapDispatchToProps = dispatch => bindActionCreators({
//   search,
}, dispatch)

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(SearchForm)