import React, { Component } from 'react';

class PhotoGalleryThumbnail extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className='thumbnail'>
				<a href={this.props.href} className='thumb-link'>
					<img src={this.props.src} alt={this.props.alt} className='thumb-img' />
				</a>
			</div>
		);
	}
}
export default PhotoGalleryThumbnail;
