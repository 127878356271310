import React, { Component } from "react";
import {Link} from "..";
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import links from '../../data/links.js';
import './Footer.scss';
const links = require('../../data/links.js');
class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		let bgClass = '';
		let fgClass = '';
		if (this.props.back){ bgClass = 'bg-'+this.props.back }
		if (this.props.front){ fgClass = 'fg-'+this.props.front }

		return (
			// <footer className={`globalfooter ws-footer bg-${this.props.back} fg-${this.props.front}`}>
			<footer 
				{...this.props}
				className={classNames(`globalfooter ws-footer`,fgClass,bgClass)}
			>
				<section className="main-section">
					<div className="liner">

					<ul className="gf-nav">
						<li className="title">Navigation</li>
						<li><Link to="/">Home</Link></li>
						<li><Link to="/portfolio">Portfolio</Link></li>
						<li><Link to="/contact">Contact</Link></li>
						<li><Link to="/photos">Photography</Link></li>
					</ul>
					
					<ul className="gf-nav">
						<li className="title">Social</li>
						<li> <Link to={links.instagram}> Instagram </Link> </li>
						<li> <Link to={links.twitter}> Twitter </Link> </li>
						<li> <Link to={links.linkedin}> LinkedIn </Link> </li>
						<li> <Link to={links.github}> GitHub </Link> </li>
						<li> <Link to={links['500px']}> 500px </Link> </li>
					</ul>

					<ul className="gf-nav">
						<li className="title">Development</li>
						<li> <Link to={links.github}> GitHub </Link> </li>
						<li> <Link to={links.linkedin}> LinkedIn </Link> </li>
						<li> <Link to="/resume">Resume</Link></li>
						<li> <Link to="/portfolio">Portfolio</Link></li>
					</ul>
			

					<ul className="gf-nav">
						<li className="title">About the Site</li>
						<li><Link to="/site/info">Information</Link></li>
						<li><Link to="/site/credits">Credits</Link></li>
					</ul>


					</div>
				</section>
				<section id="gf-fineprint" className="fineprint-section ws-row">
					<p className="fineprint">&copy; Copyright 2020 Wayne Cheng</p>
				</section>
			</footer>
		);
	}
}
export default Footer;

Footer.propTypes = {
	back: PropTypes.string,
	front: PropTypes.string,
};