import React from 'react'
import PropTypes from 'prop-types'

const Eyebrow = props => {

	return(
		<p className="eyebrow">
			{props.text}
			{props.children}
		</p>
	)
}
export default Eyebrow

Eyebrow.propTypes = {
	text: PropTypes.string,
}