import React, {Component} from 'react';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import './PhotoDisplay.scss';
import {} from '../';
// import photoInfo from '../../data/photos.json';
import photoInfo from '../../data/photo_data';

class PhotoDisplay extends Component {
	constructor(props) {
		super(props);
		this.state = {
			imgID   : '',
			photo   : {},
			title   : '',
			caption : 'Photo by Wayne Cheng',
			width   : 0,
			height  : 0,
		};
	}
	// componentDidMount = () => {
	// 	this.setImageData();
	// };
	componentDidUpdate = (prevProps, prevState) => {
		if (prevProps.imgID !== this.props.imgID) {
			this.setImageData();
		}
	};

	setImageData = () => {
		const {imgID} = this.props;

		if (imgID.length > 0 && photoInfo[imgID]) {
			const photo = photoInfo[imgID];
			const {title, caption, w, h, aspect, location} = photo;
			this.setState({
				imgID,
				photo,
				title,
				caption,
				width   : w,
				height  : h,
				aspect,
				location,
			});
		}
	};

	formatDate = date => moment(date).format('MMMM D, YYYY');

	render() {
		const {imgID, title, caption, width, height} = this.state;
		const {date, location} = this.state.photo;

		return (
			<article className='photo-display-article'>
				<section className='photo-display-container'>
					<figure className={'photo-display'}>
						<div
							className={classNames('display-box bounds', {loading: imgID === ''})}
							style={{
								// maxWidth  : width,
								// maxHeight : height,
							}}
						>
							{this.state.imgID && (
								<img
									thumb-src={`https://s3-us-west-2.amazonaws.com/assets.che.ng/img/thumb/${imgID}.jpg`}
									src={`https://s3-us-west-2.amazonaws.com/assets.che.ng/img/2k/${imgID}.jpg`}
									alt={caption}
									className='actual-photo content'
									style={{
										maxWidth  : width,
										maxHeight : height,
									}}
								/>
							)}
						</div>
						<figcaption className='photo-info-container px-8 sm__px-4 md__px-0'>
							<div className='content photo-info bounds flex flex-wrap'>
								<div className='pi-hero w-full sm__w-2/3 mb-3'>
									<h1 className='pi-headline headline'>{title}</h1>
									<p className='pi-subheadline subheadline'>{caption}</p>
								</div>
								<aside className='pi-details w-full sm__w-1/3 mb-3'>
									<ul>
										{location && (
											<li>
												<FontAwesomeIcon icon='map-marker-alt' />
												<span>{location}</span>
											</li>
										)}
										{date && (
											<li>
												{/* <FontAwesomeIcon icon='calendar-alt' /> */}
												<FontAwesomeIcon icon='calendar' />
												<span>{this.formatDate(date)}</span>
											</li>
										)}
									</ul>
								</aside>
							</div>
						</figcaption>
					</figure>
				</section>
			</article>
		);
	}
}
export default PhotoDisplay;

PhotoDisplay.defaultProps = {
	imgID : '',
};
