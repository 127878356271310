import React from 'react';
import './PageRoot.scss';
import classNames from 'classnames';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types'


const PageRoot = props => {
	return (
		<div 
			// {...props}
			onScroll={props.onScroll}
			id={props.id}
			className={classNames('page-root',props.className)}
		>
			
			{/* 
				If a title is provided via props, the page's title will be set
				to the provided title using react-helmet. The provided title is
				attached to the slug that is used across the entire site, rather
				than passing in the entire title each time. This ensures the titles
				are consistent. In previous versions where the entire title was
				set for each page, it ended up being all over the place. (e.g. some 
				would be "che.ng - title" and others would be "wayne - title")
			*/}
			{props.page_title && (
				<Helmet title={`${props.titleBase} - ${props.page_title}`} /> 
			)}

			{props.children}
		</div>
	);
};
export default PageRoot;

PageRoot.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	page_title: PropTypes.string,
	titleBase: PropTypes.string,
}

PageRoot.defaultProps = {
	titleBase: 'wayne',
}
