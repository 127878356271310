import React from 'react';

const TestArticle = () => (
	<article>
		<h1>Brunch Polaroid</h1>
		<h2>McSweeney's craft beer fixie Odd Future hashtag Kickstarter tattooed</h2>
		<h3>Pitchfork fanny pack locavore chillwave ethical master cleanse viral Pinterest 3 wolf moon</h3>
		<h4>Echo Park small batch farm-to-table hella High Life bicycle rights VHS Intelligentsia occupy whatever</h4>
		<h5>
			Crucifix Brooklyn Schlitz literally Shoreditch deep v bitters typewriter synth viral trust fund small batch
			sustainable paleo dreamcatcher cred Kickstarter wolf Vice beard
		</h5>
		<h6>
			Banksy Thundercats gentrify Odd Future semiotics raw denim Tumblr banh mi brunch gluten-free Carles occupy quinoa
			3 wolf moon American Apparel aesthetic
		</h6>
		<p>
			Mustache chia keytar plaid Etsy organic hoodie wayfarers XOXO messenger bag fanny pack fashion axe you probably
			haven't heard of them salvia roof party shabby chic locavore banjo photo booth Austin retro mixtape High Life
			direct trade distillery farm-to-table selfies chillwave tofu whatever freegan McSweeney's Bushwick iPhone ugh
			meggings seitan cornhole forage cardigan hella tousled umami swag Neutra church-key +1 pickled PBR fixie heirloom
			twee Pinterest squid polaroid gastropub narwhal street art biodiesel pork belly pop-up fap asymmetrical Cosby
			sweater normcore ethical vegan food truck cray Echo Park Intelligentsia Truffaut skateboard try-hard tote bag
			slow-carb mlkshk tattooed sartorial VHS Pitchfork actually 8-bit 90's Blue Bottle vinyl Tonx Godard bicycle rights
			lo-fi pug authentic Helvetica kogi irony yr before they sold out drinking vinegar put a bird on it master cleanse
			lomo Portland selvage hashtag sriracha fingerstache
		</p>
		<p>
			DIY kale chips four loko Williamsburg disrupt single-origin coffee scenester flexitarian letterpress next level
			jean shorts readymade meh artisan blog ennui leggings bespoke YOLO craft beer Marfa keffiyeh post-ironic art party
			pour-over butcher stumptown mumblecore chambray kitsch Wes Anderson flannel flexitarian VHS viral Schlitz Echo
			Park Bushwick tousled authentic fingerstache four loko dreamcatcher organic kale chips Portland Odd Future ugh
			tofu farm-to-table 3 wolf moon gluten-free Brooklyn typewriter street art scenester ethical normcore single-origin
			coffee sustainable distillery master cleanse small batch +1 keffiyeh crucifix banh mi umami Carles swag fixie tote
			bag sriracha narwhal Intelligentsia leggings Banksy photo booth ennui yr Pinterest irony Blue Bottle aesthetic
			occupy quinoa pork belly meggings sartorial mustache biodiesel locavore mixtape iPhone deep v mlkshk mumblecore
			twee skateboard cornhole pop-up bespoke hoodie next level Williamsburg Marfa flannel Kickstarter synth whatever
			American Apparel gentrify meh jean shorts Etsy chillwave High Life lo-fi plaid wayfarers asymmetrical craft beer
			church-key PBR cray freegan chia 8-bit hella Truffaut.
		</p>
	</article>
);
export default TestArticle;

export const TestArticleMinimal = () => (
	<article>
		<h1>Heading 1</h1>
		<h2>Heading 2</h2>
		<h3>Heading 3</h3>
		<h4>Heading 4</h4>
		<h5>Heading 5</h5>
		<h6>Heading 6</h6>
		<p>Paragraph</p>
		<pre>
			<code>const diamonds = "forever";</code>
		</pre>
	</article>
);
