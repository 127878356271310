import React, { Component } from 'react';
// import Helmet from "react-helmet";
import { Nav, Card, Row, Footer, PageRoot } from '../../components';
import './Portfolio.scss';
// import links from "../data/links.js";
const links = require('../../data/links.js');
const cubeSource = 'icons/cube-yellow.png';

class PortfolioPage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	// render //////////////////////////////////////////////////
	render() {
		const { vrooms, mypass, jobba, hangman, hotdog } = links.projects;

		return (
			// <div id="portfolio-root">
			<PageRoot id='portfolio-root' className="portfolio-root" page_title='portfolio'>
				{/* <Helmet title="wayne - portfolio" /> */}
				<Nav back='transparent' front='white' />
				<main>
					<header id='portfolio-header' className='ws-header baseline'>
						<h1 className=''>
							Wayne's <strong>Portfolio</strong>
						</h1>
						<div className='header-content'>
							<img
								id='cube-decor-0'
								className='ws-backdrop cube-decor'
								src={cubeSource}
								alt='A-Frame decoration'
								aria-hidden='true'
							/>
							<img
								id='cube-decor-1'
								className='ws-backdrop cube-decor'
								src={cubeSource}
								alt='A-Frame decoration'
								aria-hidden='true'
							/>
							<h2 className='headline'>VRooms</h2>
							<h4 className='subheadline'>In-Browser Virtual Reality for Real Estate</h4>
							<a className='featured-link' href={vrooms.demo}>
								Tour VRooms
							</a>
							<a className='subfeatured-link' href={vrooms.repo}>
								GitHub
							</a>
						</div>
					</header>

					<article className='card-wrap'>
						{/* <Section kind="full-width">
							<Row>
								<h2 className="headline">MyPass</h2>
								<h4 className="subheadline">
									Biometric user authentication using face and voice
									identification. Powered by AWS Rekognition.
								</h4>
							</Row>
						</Section> */}
						{/* MyPass */}
						<Row>
							<Card
								title='MyPass'
								subtitle='Biometric user authentication using face and voice identification. Powered by AWS Rekognition.'
								// theme="ws-dark-gunmetal"
								theme='ws-blue'
								// actions={mypass.urls}
								actions={[
									{
										text: "GitHub",
										to: "https://github.com/wayncheng/mypass",
										type: "beta"
									},
									// {
									// 	text: "Try",
									// 	to: "https://my-pass.herokuapp.com",
									// 	type: "alpha"
									// }
								]}
							/>
						</Row>
						{/* Jobba */}
						<Row>
							<Card
								title='Jobba'
								subtitle='Job portal that aggregates listings from Indeed, Dice, Github Jobs, Linkup, Authentic Jobs, and uses Glassdoor for each company/salary info.'
								theme='ws-green'
								badge={{
									text: 'Under Maintenance',
									type: 'warning'
								}}
								actions={[
									{
										to   : jobba.repo,
										text : 'GitHub',
										type : 'beta',
									},
									{
										to   : jobba.demo,
										text : 'Search',
										type : 'alpha',
										// type : 'under-maintenance',
									},
								]}
							/>
						</Row>
						<Row>
							{/* Hotdog Or Not */}
							<Card
								title='Hotdog or Not'
								subtitle='Simple web app to identify hotdogs in images. Inspired by HBO&#39;s Silicon Valley.'
								theme='ws-orange'
								actions={[
									{
										to   : hotdog.repo,
										text : 'GitHub',
										type : 'beta',
									},
									{
										to   : hotdog.demo,
										text : 'Examine',
										type : 'alpha',
									},
								]}
							/>
							{/* Hangman */}
							<Card
								title='Triple Crown Hangman'
								subtitle='Triple-Crown themed version of Hangman. Win 4 straight games to win the Triple Crown! But don&#39;t take your victory lap just yet, each win increases the difficulty.'
								theme='ws-purple'
								actions={[
									{
										to   : hangman.repo,
										text : 'GitHub',
										type : 'beta',
									},
									{
										to   : hangman.demo,
										text : 'Play',
										type : 'alpha',
									},
								]}
							/>
						</Row>
					</article>
				</main>
				<Footer />
				{/* </div> */}
			</PageRoot>
		);
	}
}
export default PortfolioPage;
