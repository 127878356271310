import React, { Component } from 'react';
import { Nav, Footer, PageRoot } from '../../components';
import ResumeHeader from './ResumeHeader';
import ResumeSectionContainer from './ResumeSectionContainer';
import ResExperience from './ResExperience';
import resumeData from '../../data/resume.js';
import './Resume.scss';

class Resume extends Component {
	constructor(props) {
		super(props);
		this.state = {
			view: 'full',
		};
	}

	componentWillMount = () => document.querySelector('html').setAttribute('id', 'resume-page');
	componentWillUnMount = () => document.getElementById('resume-page').removeAttribute('id');

	componentDidMount = () => {
		let { view } = this.props.match.params;
		if (view) {
			console.log('this.props.match.params.view:', view);
			this.setState({ view });
		}
	};

	handleViewChange = event => {
		event.preventDefault();
		let { target } = event;
		let view = target.getAttribute('data-view');

		if (view !== this.state.view) {
			console.log('view changed to:', view);
			this.setState({ view });
		}
	};

	// render //////////////////////////////////////////////////
	render() {
		const resumeProjects = ['vrooms', 'mypass', 'jobba'];

		return (
			<PageRoot id="resume-root" className={`resume-page view-${this.state.view}`} page_title="resume">
				{/* <Nav back="black" front="white" /> */}
				<Nav back="dark" front="white" />
				{/* Control Bar */}
				<aside className="control-bar nav-bumper">
					<div className="control-bar-content">
						{this.state.view === 'full' ? (
							<button className="ws-btn" data-view="links" onClick={this.handleViewChange}>
								Show Links Only
							</button>
						) : (
							<button className="ws-btn" data-view="full" onClick={this.handleViewChange}>
								Show Full Resume
							</button>
						)}
					</div>
				</aside>
				<main>
					<article className="resume-container content-container">
						<ResumeHeader />
						<ResumeSectionContainer className="hide-on-links-only" label="Technical Skills">
							<p>{resumeData.skills.join(', ')}</p>
						</ResumeSectionContainer>
						<ResumeSectionContainer className="hide-on-links-only" label="Work Experience">
							{resumeData.experience.map((exp, index) => {
								return <ResExperience data={exp} dataKey={index} key={'work-experience-' + index} />;
							})}
						</ResumeSectionContainer>
						<ResumeSectionContainer label="Applications Built">
							{resumeProjects.map((proj, index) => {
								let data = resumeData.projects[proj];
								return <ResExperience data={data} dataKey={proj} key={'experience-' + proj} />;
							})}
						</ResumeSectionContainer>
					</article>
				</main>
				<Footer />
			</PageRoot>
		);
	}
}
export default Resume;
