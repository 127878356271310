import React from 'react';
import classNames from 'classnames';

const Row = (props) => {

	return ( 
		<div className={ classNames( "ws-row", props.className ) }>
			{props.children}
		</div>
	)
}
export default Row;

Row.defaultProps = {
	className: '',
}