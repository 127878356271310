import React from "react";
import {
	Nav, 
	// LinkList,
	Footer,
	PageRoot,
	Header,
	Section,
	Link,
} from '../../components';
// import links from '../data/links.js';
const links = require('../../data/links.js');

const Contact = props => (
	<PageRoot 
		id="contact-root" 
		page_title="contact"
		// className="centered-layout" 
	>
		<Nav back="transparent" front="white" />


		<main>
			{/* <header className="">
				<div className="liner">
					<h1 className="headline">
						How Do I Reach Wayne?
					</h1>
				</div>
			</header> */}
			<Header>

				<h3 className="site-title headline">Contact</h3>
			</Header>

			<Section className="section">
				<table>
					<tbody>
						<tr>
							<td>Email</td>
							<td> 
								<Link to={links.mailto}> wayne@che.ng </Link> 
							</td>
						</tr>
						<tr>
							<td>Code</td>
							<td> 
								<Link to={links.github}> GitHub (wayncheng) </Link> 
							</td>
						</tr>
						<tr>
							<td>Connect</td>
							<td> 
								<Link to={links.linkedin}> LinkedIn (wayncheng) </Link> 
							</td>
						</tr>
						<tr>
							<td>Follow</td>
							<td> 
								<Link to={links.instagram}> Instagram (@wayncheng) </Link> 
							</td>
						</tr>
					</tbody>
				</table>
			</Section>
			
			{/* <Section id="body-section" className="section">
				<div className="flex-boundary">
					<div className="subsection">
						<p className="eyebrow"> Email </p>
						<Link to={links.mailto}> wayne@che.ng </Link> 
					</div>
					<div className="subsection">
						<p className="eyebrow"> GitHub </p>
						<Link to={links.github}> @wayncheng </Link> 
					</div>
					<div className="subsection">
						<p className="eyebrow"> LinkedIn </p>
						<Link to={links.linkedin}> @wayncheng </Link> 
					</div>
					<div className="subsection">
						<p className="eyebrow"> Instagram </p>
						<Link to={links.instagram}> @wayncheng </Link> 
					</div>
				</div>
			</Section> */}

			{/* <section id="link-section" className="">
				<LinkList/>
			</section> */}
		</main>

		<Footer/>

	</PageRoot>
)
	

export default Contact;
